import React, { useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { useAuth0 } from "@auth0/auth0-react";
import { connect } from 'react-redux';

import {
  setAuthInfo,
  setDealList,
  setLoading,
  setAccountInfo,
  setNotifyRule,
  setOrgMember,
  setSheetDataForm,
  setFormInfos,
  setTaskLog,
  setTaskLogPage,
  setScreen
} from './actions'
import { taskLogPage } from './reducer';
import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  // Dashboard as DashboardView,
  // ProductList as ProductListView,
  // UserList as UserListView,
  // Typography as TypographyView,
  // Icons as IconsView,
  Account as AccountView,
  // Settings as SettingsView,
  SignUp as SignUpView,
  SignIn as SignInView,
  UserManager as UserManagerView,
  NotFound as NotFoundView,
  TaskLog as TaskLogView,
  Analyz as AnalyzView
} from './views';

import envConfig from './configuration/env.json';

import { DealList as DealListView, DealListDetail as DealListDetailView} from './views/DealList';
import { httpRequest, reqObj2From } from './lib';

// const NEED_VERIFY_MESSAGE = "Please verify your email before logging in.";

const MEDIUM_WIDTH = 950;
const MOBILE_WIDTH = 768;

const getScreenSize = () => {
  if (window.innerWidth < MOBILE_WIDTH) {
    return 'mobile';
  } else if (window.innerWidth < MEDIUM_WIDTH) {
    return 'medium';
  } else {
    return 'large';
  }
};

const Routes = (props) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const initProfile = (token, callback) => {
    httpRequest('GET', '/api/account', {}, token, (statusCode, body) => {
      if (!body.status) {
        return;
      }
      props.setAccountInfo({...body.info});
      callback();
    });
  };
  const initCaseRule = (token) => {
    httpRequest('GET', '/api/notifyrule', {}, token, (statusCode, response) => {
      const { status, notifyRule } = response; // { status, message, notifyRule }
      if (!status) { return; }
      props.setNotifyRule(notifyRule);
    });
  }
  const initOrgMember = (token) => {
    httpRequest('GET', '/api/manager/orgmember', {}, token, (statusCode, response) => {
      const { status, members } = response; // { status, message, members }
      if (!status) { return; }
      props.setOrgMember(members);
    });
  }
  const loadDealList = (token, formInfos) => {
    httpRequest('GET', '/api/dealtrack', {}, token, (statusCode, response) => {
      const { status, data } = response; // { status, message, data }
      if (!status) { return; }
      const formatData = data.map((row) => {
        const ownerObj = {
          owner_name: row.owner.name,
          owner_email: row.owner.email,
          owner_uid: row.owner.user_uid
        };
        const formInfoIndex = formInfos.findIndex((formInfo) => {
          return formInfo.name === row.dataForm;
        });
        return { ...reqObj2From(formInfos[formInfoIndex], row), ...ownerObj };
      });
      props.setDealList(formatData);
      props.setLoading(false);
    });
  };
  const initSheetDataForm = (token) => {
    httpRequest('GET', '/api/data_form', {}, token, (statusCode, response) => {
      const { status, datas } = response; // { status, message, datas }
      if (!status) { return; }
      props.setSheetDataForm(datas);
    });
  };
  const initFormMap = (token, callback) => {
    httpRequest('GET', '/api/form', {}, token, (statusCode, response) => {
      const { status, formInfos } = response; // { status, message, formInfos }
      if (!status) { return; }
      props.setFormInfos(formInfos);
      callback(formInfos);
    });
  };

  const initTaskLog = (token) => {
    const { page, perPage } = props.taskLogPage;
    const url = `/api/task_log?page=${page}&perPage=${perPage}`;
    httpRequest('GET', url, {}, token, (statusCode, body) => {
      if (!body.status) {
        return;
      }
      if (body.taskLogs.length < perPage) {
        props.setTaskLogPage({ page, perPage, next: false });
      }
      props.setTaskLog(body.taskLogs);
    });
  };

  const handleWindowSizeChange = () => {
    props.setScreen(getScreenSize());
  };

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently({
        audience: envConfig.audience,
        scope: envConfig.scope
      }).then((accessToken) => {
        // console.log({ accessToken });
        props.setAuthInfo({ ...user, accessToken });
        initProfile(accessToken, () => {
          // When a request is made at the same time, two new users will be created in the initialization process
          initFormMap(accessToken, (formInfos) => {
            loadDealList(accessToken, formInfos);
          });
        });
        initCaseRule(accessToken);
        initOrgMember(accessToken);
        initSheetDataForm(accessToken);
        initTaskLog(accessToken);
      }).catch((err) => {
        // console.log(err);
      });
    }
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [isAuthenticated]);

  return (
    <Switch>
      {/****************************
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/"
      />
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      ****************************/}
      {/****************************
      <RouteWithLayout
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      ****************************/}
      <RouteWithLayout
        component={DealListView}
        exact
        layout={MainLayout}
        path="/"
      />
      <RouteWithLayout
        component={DealListView}
        exact
        layout={MainLayout}
        path="/pipeline"
      />
      <RouteWithLayout
        component={DealListDetailView}
        layout={MainLayout}
        path="/pipeline/:caseid"
      />
      <RouteWithLayout
        component={TaskLogView}
        exact
        layout={MainLayout}
        path="/task_log"
      />
      {/****************************
      <RouteWithLayout
        component={TypographyView}
        exact
        layout={MainLayout}
        path="/typography"
      />
      <RouteWithLayout
        component={IconsView}
        exact
        layout={MainLayout}
        path="/icons"
      />
      ****************************/}
      <RouteWithLayout
        component={AnalyzView}
        exact
        layout={MainLayout}
        path="/analyz"
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={UserManagerView}
        exact
        layout={MainLayout}
        path="/usermanager"
      />
      {/****************************
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      ****************************/}
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

const mapStateToProps = state => {
  const { taskLogPage } = state;
  return { taskLogPage };
};

export default connect(mapStateToProps, {
  setAuthInfo,
  setDealList,
  setLoading,
  setAccountInfo,
  setNotifyRule,
  setOrgMember,
  setSheetDataForm,
  setFormInfos,
  setTaskLog,
  setTaskLogPage,
  setScreen
})(Routes);
