import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, injectIntl } from 'react-intl';
import { NavLink as RouterLink } from 'react-router-dom';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';

import TableBodyCellViewer from './TableBodyCellViewer';

const HIDENT_DR_NUMBER_CASE_STATUS = ['Expired'];

const EditableTableCell = (props) => {
  if (props.editable) {
    return (
      <TableCell align={'center'} padding="none">
        {props.children}
        {
          props.editing ? (
            <>
              <IconButton onClick={props.onEditSaveClick}>
                <CheckCircleIcon style={{ fontSize: '16px', color:'#888888' }}/>
              </IconButton>
              <IconButton onClick={props.onEditCancelClick}>
                <CancelIcon style={{ fontSize: '16px', color:'#888888' }}/>
              </IconButton>
            </>
          ) : (
            <IconButton onClick={props.onEditClick}>
              <EditIcon style={{ fontSize: '16px', color:'#888888' }}/>
            </IconButton>
          )
        }
      </TableCell>
    );
  }
  return (
    <TableCell align={'center'} padding="none">
      {props.children}
    </TableCell>
  );
};

EditableTableCell.propTypes = {
  editable: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  onEditSaveClick: PropTypes.func.isRequired,
  onEditCancelClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired
};

const TableBodyRow = (props) => {
  const [focusIndex, setFocusIndex] = useState(-1);
  const [editRowIndex, setEditRowIndex] = useState(-1);
  const [editCellIndex, setEditCellIndex] = useState(-1);
  const [editCellValue, setEditCellValue] = useState(null);

  const onEditSaveClick = (event) => {
    props.onCellChange(props.rows[editRowIndex], editRowIndex, props.headers[editCellIndex], editCellIndex, editCellValue);
    setEditRowIndex(-1);
    setEditCellIndex(-1);
    setEditCellValue(null);
  };

  const onEditClick = (rowIndex, headCellIndex) => (event) => {
    setEditCellValue(props.rows[rowIndex][props.headers[headCellIndex].id]);
    setEditRowIndex(rowIndex);
    setEditCellIndex(headCellIndex);
  };
  const onEditCancelClick = () => {
    setEditRowIndex(-1);
    setEditCellIndex(-1);
  };

  const onRowClick = (index) => (event) => {
    if (focusIndex !== index) {
      setEditRowIndex(-1);
      setEditCellIndex(-1);
    }
    setFocusIndex(index);
  };

  return (
    <TableBody>
      {
        props.rows.map((row, rowIndex) => {
          let rowStyle = {}; // default style
          let rowClassName = ''; // default class name

          let dataFormIndex = props.formInfos.findIndex((formInfo) => {
            return formInfo.name === row.dataForm;
          });
          if (dataFormIndex === -1) {
            // not found, use default form index
            dataFormIndex = 0;
          }

          if (typeof props.onRowStyleFunc === 'function') {
            const rowStyleJson = props.onRowStyleFunc(row, rowIndex, focusIndex);
            rowStyle = rowStyleJson.style;
            rowClassName = rowStyleJson.className;
          }

          return (
            <TableRow hover role='checkbox' tabIndex={-1} key={`${rowIndex}`} style={rowStyle} className={rowClassName} onClick={onRowClick(rowIndex)}>
              {
                props.headers.map((headCell, headCellIndex) => {
                  const cellAttInfo = props.formInfos[dataFormIndex].keysMap;
                  const cellKeyStr = `table_cell_${rowIndex}_${headCellIndex}`;
                  let editing = Boolean(headCell.editable && editRowIndex === rowIndex && editCellIndex === headCellIndex);

                  let cellValue = editing ? editCellValue : row[headCell.id];
                  if (typeof props.onViewFunc === 'function') {
                    cellValue = props.onViewFunc(row, rowIndex, headCell, headCellIndex, cellValue);
                  }

                  // TODO: Customized Adjustment icon btn
                  if (headCell.type === 'edit') {
                    editing = !HIDENT_DR_NUMBER_CASE_STATUS.includes(row.case_status);
                  }
                  return (
                    <EditableTableCell
                      editable={headCell.editable && focusIndex === rowIndex}
                      editing={editing}
                      onEditSaveClick={onEditSaveClick}
                      onEditCancelClick={onEditCancelClick}
                      onEditClick={onEditClick(rowIndex, headCellIndex)}
                    >
                      <TableBodyCellViewer
                        cellAttInfo={cellAttInfo[headCell.id]}
                        headCellInfo={headCell}
                        editing={editing}
                        value={cellValue}
                        onCellChange={(value) => {
                          setEditCellValue(value);
                        }}
                        onCellClick={() => {
                          props.onCellClick(row, rowIndex, headCell, headCellIndex);
                        }}
                      />
                    </EditableTableCell>
                  );
                })
              }
            </TableRow>
          );
        })
      }
    </TableBody>
  );
};

TableBodyRow.propTypes = {
  rows: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  formInfos: PropTypes.array.isRequired,
  onRowStyleFunc: PropTypes.func, // for display style. (row, rowIndex, focusIndex) => { style: {}, className: '' }
  onViewFunc: PropTypes.func.isRequired, // for display view. (row, rowIndex, headCell, headCellIndex, cellValue) => string
  onCellChange: PropTypes.func, // trigger by press save btn. (row, rowIndex, headCell, headCellIndex, cellValue) => void
  onCellClick: PropTypes.func // press btn cell. (row, rowIndex, headCell, headCellIndex) => void
};

export default injectIntl(TableBodyRow);
