export default {
  contained: {
    boxShadow:
      '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    backgroundColor: '#23a455',
    color: '#ffffff',
    '&:focus': {
      backgroundColor: '#23a455'
    },
    '&:hover': {
      backgroundColor: '#23a455'
    }

  }
};
