import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
// material-ui
import Checkbox from '@material-ui/core/Checkbox';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const EnhancedTableHead = (props) => {
  const { order, orderBy, heads, onRequestSort } = props;
  const { pickBox, pickIndet, pickAllChecked, onPickAllClick } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {
          pickBox && <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={pickIndet}
              checked={pickAllChecked}
              onChange={onPickAllClick}
              inputProps={{ 'aria-label': 'select all' }}
            />
          </TableCell>
        }
        {
          heads.map((headCell, headCellIndex) => {
            if (headCell.sortable) {
              return (
                <TableCell
                  key={`head-${headCell.id}-${headCell.id}`}
                  style={{ minWidth: headCell.minWidth }}
                  align={'center'}
                  padding={'none'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    <FormattedMessage id={headCell.label_id}/>
                  </TableSortLabel>
                </TableCell>
              );
            }
            return (
              <TableCell
                key={`head-${headCell.id}-${headCell.id}`}
                style={{ minWidth: headCell.minWidth }}
                align={'center'}
                padding="none"
              >
                <FormattedMessage id={headCell.label_id}/>
              </TableCell>
            );
          })
        }
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  heads: PropTypes.array.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  pickBox: PropTypes.bool, // enable/disable pick feature
  pickIndet: PropTypes.bool,
  pickAllChecked: PropTypes.bool,
  onPickAllClick: PropTypes.func
};

export default injectIntl(EnhancedTableHead);
