import { combineReducers } from 'redux';
import account from './account';
import auth from './auth';
import dealList from './dealList';
import loading from './Loading';
import notifyRule from './notifyRule';
import orgMember from './orgMember';
// import filterStr from './filterStr';
// import statusFilter from './statusFilter';
// import updateRangeFilter from './updateRangeFilter';
// import productTypeFilter from './productTypeFilter';
// import progressFilter from './progressFilter';
// import estimatedClosingFilter from './estimatedClosingFilter';
import filterInfo from './filterInfo';
import order from './order';
import orderBy from './orderBy';
// import filterOwner from './filterOwner';
// import greedyFilterStr from './greedyFilterStr';
import sheetDataForm from './sheetDataForm';
// import filterDataForm from './filterDataForm.js';
import formInfos from './formInfos.js';
import screen from './screen.js';
import taskLog from './taskLog.js';
import taskLogPage from './taskLogPage.js'

export default combineReducers({
  account,
  auth,
  dealList,
  loading,
  notifyRule,
  orgMember,
  // filterStr,
  // statusFilter,
  // updateRangeFilter,
  // productTypeFilter,
  // progressFilter,
  // estimatedClosingFilter,
  filterInfo,
  order,
  orderBy,
  // filterOwner,
  // greedyFilterStr,
  sheetDataForm,
  // filterDataForm,
  formInfos,
  screen,
  taskLog,
  taskLogPage
});
