import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import {
  LatestSales,
  UsersByDevice,
  LatestProducts,
  LatestOrders,
  WaitApprove,
  Approved,
  Expired,
  Closed,
  LatestView
} from './components';
import { setDealList } from '../../actions';
import { httpRequest, reqObj2From } from '../../lib';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = (props) => {
  const classes = useStyles();
  /*
  useEffect(() => {
    httpRequest('GET', 'api/dealtrack', {}, props.auth.accessToken, (statusCode, response) => {
      const { status, message, data } = response;
      if (!status) {
        console.log({ status, message });
        return;
      }
      const formatData = data.map((row) => {
        return reqObj2From(row);
      });
      console.log('Dashboard', { formatData });
      props.setDealList(formatData);
    });
  }, [props.auth.accessToken]);
  */
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <WaitApprove />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Approved />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Expired />
        </Grid>
        <Grid
          item
          lg={3}
          sm={6}
          xl={3}
          xs={12}
        >
          <Closed />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <LatestView />
        </Grid>
        {/*
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <UsersByDevice />
        </Grid>
        <Grid
          item
          lg={4}
          md={6}
          xl={3}
          xs={12}
        >
          <LatestProducts />
        </Grid>
        <Grid
          item
          lg={8}
          md={12}
          xl={9}
          xs={12}
        >
          <LatestOrders />
        </Grid>
        */}
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  const { auth, dealList } = state;
  return { auth, dealList };
};

export default connect(mapStateToProps, { setDealList })(withAuthenticationRequired(Dashboard));
// export default connect(mapStateToProps, { setDealList })(Dashboard);
