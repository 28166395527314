import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { injectIntl, FormattedMessage, FormattedDate, FormattedTime } from 'react-intl';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { SearchFilter } from '../../components/CustComponents';
import TaskLogDialog from './TaskLogDialog';
import Loader from '../../image/Loading.gif';

import { httpRequest, reqObj2From, form2ReqObj } from '../../lib';

import { setLoading, setTaskLog, setTaskLogPage } from '../../actions';

let timeoutId = null;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  shineRow: {
    '&:hover': {
      backgroundColor: '#DDDDDD !important'
    },
    background: '#3dabe248'
  },
  warnRow: {
    '&:hover': {
      backgroundColor: '#DDDDDD !important'
    },
    background: '#FFD0D0'
  },
  calmRow: {
    '&:hover': {
      backgroundColor: '#DDDDDD !important'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  title: {
    flex: '1 1 100%'
  }
}));

const taskLogFilter = (rows, filterInfo) => {
  return rows;
};

const descendingComparator = (a, b, orderBy) => {
  if (orderBy === 'reqTime') {
    const aValue = new Date(a.reqTime).getTime();
    const bValue = new Date(b.reqTime).getTime();
    if (bValue < aValue) {
      return -1;
    }
    if (bValue > aValue) {
      return 1;
    }
    return 0;
  }
  if (orderBy === 'result') {
    const aValue = a.resultData ? (a.resultData.status ? 'success' : 'failed') : 'processing';
    const bValue = b.resultData ? (b.resultData.status ? 'success' : 'failed') : 'processing';
    if (bValue < aValue) {
      return -1;
    }
    if (bValue > aValue) {
      return 1;
    }
    return 0;
  }
  const aValue = a[orderBy] || '';
  const bValue = b[orderBy] || '';

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const renderQueryParams = (json) => {
  return (
    Object.keys(json).map((key) => {
      return `${key}=${encodeURI(json[key])}`;
    }).join('&')
  );
};

const FilterComponents = (props) => {
  const onFilterInfoChange = (key) => (event) => {
    props.onFilterInfoChange(key, event.target.value);
  };

  return (
    <div>
      <SearchFilter
        enable={true}
        style={{ minWidth: '250px' }}
        title={props.intl.formatMessage({ id: 'search' })}
        placeholder={props.intl.formatMessage({ id: 'search' })}
        value={props.filterInfo.filter}
        onChange={onFilterInfoChange('filter')}
      />
    </div>
  );
};

/***********************************/
const TaslLogToolbar = (props) => {
  const { classes } = props;
  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} style={{ fontSize:'30px' }} variant='h6' component='div'>
        <FormattedMessage id='task_log_title' defaultMessage='Task Log'/>
      </Typography>
      <Tooltip title={props.intl.formatMessage({ id: 'refresh' })}>
        <IconButton onClick={props.onReloadClick}>
          <RefreshIcon fontSize='large' color='primary'/>
        </IconButton>
      </Tooltip>
      <FilterComponents
        intl={props.intl}
        onFilterInfoChange={props.onFilterInfoChange}
        filterInfo={props.filterInfo}
      />
    </Toolbar>
  );
};

const SortCellLabel = (props) => {
  const { classes, text, sortKey, order, orderBy, createSortHandler } = props;
  return (
    <TableSortLabel
      active={orderBy === sortKey}
      direction={orderBy === sortKey ? order : 'asc'}
      onClick={createSortHandler(sortKey)}
    >
      {text}
      {orderBy === sortKey ? (
        <span className={classes.visuallyHidden}>
          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
        </span>
      ) : null}
    </TableSortLabel>
  );
};

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell style={{minWidth: '100px'}} padding="checkbox">
          <FormattedMessage id="view"/>
        </TableCell>
        <TableCell style={{minWidth: '100px'}} padding="checkbox">
          <SortCellLabel
            classes={classes}
            text={props.intl.formatMessage({ id: 'operate' })}
            sortKey={'operate'}
            order={order}
            orderBy={orderBy}
            createSortHandler={createSortHandler}
          />
        </TableCell>
        <TableCell style={{minWidth: '100px'}} padding="checkbox">
          <FormattedMessage id='user_compony'/>
        </TableCell>
        <TableCell style={{minWidth: '100px'}} padding="checkbox">
          <SortCellLabel
            classes={classes}
            text={props.intl.formatMessage({ id: 'result' })}
            sortKey={'result'}
            order={order}
            orderBy={orderBy}
            createSortHandler={createSortHandler}
          />
        </TableCell>
        <TableCell style={{minWidth: '100px'}} padding="checkbox">
          <SortCellLabel
            classes={classes}
            text={props.intl.formatMessage({ id: 'owner' })}
            sortKey={'userName'}
            order={order}
            orderBy={orderBy}
            createSortHandler={createSortHandler}
          />
        </TableCell>
        <TableCell style={{minWidth: '100px'}} padding="checkbox">
          <SortCellLabel
            classes={classes}
            text={props.intl.formatMessage({ id: 'data_form' })}
            sortKey={'dataForm'}
            order={order}
            orderBy={orderBy}
            createSortHandler={createSortHandler}
          />
        </TableCell>
        <TableCell style={{minWidth: '100px'}} padding="checkbox">
          <SortCellLabel
            classes={classes}
            text={props.intl.formatMessage({ id: 'req_time' })}
            sortKey={'reqTime'}
            order={order}
            orderBy={orderBy}
            createSortHandler={createSortHandler}
          />
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

const TaskLog = (props) => {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('reqTime');
  const [openTaskLogDialog, setOpenTaskLogDialog] = useState(false);
  const [perPage, setPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [rowIndex, setRowIndex] = useState(-1);
  const [filterInfo, setFilterInfo] = useState({
    filter: ''
  });

  const classes = useStyles();

  const onRowClick = (clickIndex) => (event) => {
    setRowIndex(clickIndex);
  };

  const onTaskLogClick = (clickIndex) => () => {
    setOpenTaskLogDialog(true);
  };

  const renderUserCompany = (operate, reqInfo) => {
    try {
      if (operate === 'add_rows') {
        return reqInfo.body.rows.map((rowInfo) => {
          return rowInfo.user.compony_tw || rowInfo.user.compony_en;
        }).join(', ');
      }
      if (operate === 'update_row') {
        return reqInfo.body.data.user.compony_tw || reqInfo.body.data.user.compony_en;
      }
    } catch (err) {
      console.log('renderUserCompany failed');
      return '';
    }
    return '';
  };

  const renderTaskResult = (resData) => {
    if (!resData) {
      return 'Processing';
      // return props.intl.formatMessage({ id: 'processing' });
    }
    if (resData.status) {
      return 'Success';
      // return props.intl.formatMessage({ id: 'success' });
    }
    return 'Failed';
    // return props.intl.formatMessage({ id: 'failed' });
  };

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setRowIndex(-1);
  };

  const reloadTaskLog = (mPage, mPerPage, mFilterInfo) => {
    const queryStr = renderQueryParams({
      page: mPage,
      perPage: mPerPage,
      ...mFilterInfo
    });
    const url = `/api/task_log?${queryStr}`;
    props.setLoading(true);
    httpRequest('GET', url, {}, props.auth.accessToken, (statusCode, body) => {
      props.setLoading(false);
      if (!body.status) {
        return;
      }
      const haveNext = body.taskLogs.length >= mPerPage;
      props.setTaskLogPage({ page: mPage, perPage: mPerPage, next: haveNext });
      props.setTaskLog(body.taskLogs);
    });
  };

  const onChangePage = (event, newPage) => {
    if (newPage > props.taskLogPage.page && !props.taskLogPage.next) {
      return;
    }
    reloadTaskLog(newPage, props.taskLogPage.perPage, filterInfo);
  };

  const onChangePerPage = (event) => {
    reloadTaskLog(0, event.target.value, filterInfo);
  };

  const onFilterInfoChange = (key, value) => {
    const json = JSON.parse(JSON.stringify(filterInfo));
    json[key] = value;
    setRowIndex(-1);
    setFilterInfo(json);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      reloadTaskLog(0, props.taskLogPage.perPage, json);
    }, 1500);
  };

  const onReloadTaskLog = () => {
    reloadTaskLog(props.taskLogPage.page, props.taskLogPage.perPage, filterInfo);
  };

  const displayTableRows = stableSort(props.taskLog, getComparator(order, orderBy));
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TaslLogToolbar
          intl={props.intl}
          classes={classes}
          filterInfo={filterInfo}
          onFilterInfoChange={onFilterInfoChange}
          onReloadClick={onReloadTaskLog}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              intl={props.intl}
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={onRequestSort}
            />
            <TableBody>
              {
                displayTableRows.map((row, index) => {
                  const rowClassName = rowIndex === index ? classes.shineRow : classes.calmRow;
                  return (
                    <TableRow hover role='checkbox' tabIndex={-1} key={`${index}`} className={rowClassName} onClick={onRowClick(index)}>
                      <TableCell padding="checkbox">
                        <IconButton onClick={onTaskLogClick(index)}>
                          <VisibilityIcon style={{ color:'#dadada' }}/>
                        </IconButton>
                      </TableCell>
                      <TableCell align="left">
                        <FormattedMessage id={`operate_${row.operate || 'unknown'}`}/>
                      </TableCell>
                      <TableCell align="left">
                        {renderUserCompany(row.operate, row.requestInfo)}
                      </TableCell>
                      <TableCell align="left">
                        {renderTaskResult(row.resultData)}
                      </TableCell>
                      <TableCell align="left">
                        {row.userName}
                      </TableCell>
                      <TableCell align="left">
                        {row.dataForm}
                      </TableCell>
                      <TableCell align="left">
                      <span style={{ padding: '0px 5px 0px 0px' }}><FormattedDate value={new Date(row.reqTime)}/></span>
                      <span><FormattedTime value={new Date(row.reqTime)}/></span>
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25]}
          component="div"
          count={-1}
          rowsPerPage={props.taskLogPage.perPage}
          page={props.taskLogPage.page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangePerPage}
        />
        {/* {
          JSON.stringify(props.taskLog, null, 2)
        } */}
      </Paper>
      <TaskLogDialog
        onLoading={(load) => {
          props.setLoading(load);
        }}
        auth={props.auth}
        open={openTaskLogDialog}
        onClose={() => setOpenTaskLogDialog(false)}
        taskLog={(rowIndex > -1) ? props.taskLog[rowIndex] : null}
      />
      { props.loading && (
        <div style={{zIndex:'1324',position:'fixed', top:'0', left: '0', backgroundColor:'#D3D3D3', opacity:'0.85', height:'100vh', width:'100vw', display:'flex', justifyContent:'center', alignItems:'center'}}>
          <div style={{  textAlign:'center'}}>
            <img
              src={Loader}
              style={{ width: '200px' }}
              alt='Loading...'
            />
            <h3 style={{color:'white'}}>Loading...</h3>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth, loading, taskLog, taskLogPage } = state;
  return { auth, loading, taskLog, taskLogPage };
};

export default connect(mapStateToProps, {
  setLoading, setTaskLog, setTaskLogPage
})(withAuthenticationRequired(injectIntl(TaskLog)));
