const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const httpRequest = (method, src, jsonBody, token, callback) => {
  const request = new XMLHttpRequest();
  // nosemgrep eslint.detect-non-literal-fs-filename -- method and src is const value from code.
  request.open(method, src);
  request.setRequestHeader('Content-Type', 'application/json');
  request.setRequestHeader('Authorization', `Bearer ${token}`);
  request.send(JSON.stringify(jsonBody));
  request.onload = (e) => {
    callback(request.status, IsJsonString(request.responseText) ? JSON.parse(request.responseText) : request.responseText);
  };
  request.onerror = (error) => {
    callback(error, null);
  };
};

const row2ReqObj = (formInfo, rows) => {
  const headerObj = formInfo.map.filter((titleOjb) => {
    return titleOjb.allowEditStage.includes('Waiting');
  });
  let emptyIndex = rows.length;
  const result = rows.map((cols, rowIndex) => {
    const json = {};
    let isEmpty = true;
    cols.forEach((col, index) => {
      if (!json[headerObj[index].type]) {
        json[headerObj[index].type] = {};
      }
      if (col) {
        isEmpty = false;
      }
      if (headerObj[index].fieldType === 'mutiselect') {
        json[headerObj[index].type][headerObj[index].key] = col.split(',');
      } else {
        json[headerObj[index].type][headerObj[index].key] = col;
      }
    });
    if (isEmpty && emptyIndex === rows.length) {
      emptyIndex = rowIndex;
    }
    return json;
  });
  return result.slice(0, emptyIndex);
};

const reqObj2From = (formInfo, obj) => {
  const form = {};
  formInfo.map.filter((attr) => attr.display).forEach((map) => {
    form[`${map.type}_${map.key}`] = obj[map.type] ? obj[map.type][map.key] || '' : '';
  });
  form.dataForm = obj.dataForm;
  return form;
};

const form2ReqObj = (formInfo, forms) => {
  const rows = forms.map((form) => {
    const row = {};
    formInfo.map.filter((attr) => attr.display).forEach((map) => {
      if (!row[map.type]) {
        row[map.type] = {};
      }
      // nosemgrep eslint.detect-object-injection -- formInfo is config value
      row[map.type][map.key] = form[`${map.type}_${map.key}`];
    });
    return row;
  });
  return rows;
};

const mapsConst = (formInfo) => {
  const json = {};
  formInfo.map.forEach((map, index) => {
    json[`${map.type}_${map.key}`] = index;
  });
  return json;
}

const identName = (num, offSetStr = 'A', carry = 26) => {
	const charCode = offSetStr.charCodeAt(0);
	let weights = [num];
	let index = 0;

	while (weights[index] >= carry) {
		weights[index + 1] = Math.floor(weights[index] / carry) - 1;
		weights[index] %= carry;
		index += 1;
	}

	return weights.reverse().map((n) => {
		return String.fromCharCode(charCode + n)
	}).join('');
};


export {
  mapsConst,
  form2ReqObj,
  httpRequest,
  reqObj2From,
  row2ReqObj,
  identName
};
