const CHART_CONSTANTS = {
  BACKGROUND_COLOR: [
    'rgba(255, 99, 132, 0.3)', // 紅色
    'rgba(255, 159, 64, 0.3)', // 橘色
    'rgba(255, 205, 86, 0.3)', // 黃色
    'rgba(75, 192, 192, 0.3)', // 青色
    'rgba(54, 162, 235, 0.3)', // 藍色
    'rgba(153, 102, 255, 0.3)', // 紫色
    'rgba(201, 203, 207, 0.3)' // 灰色
  ],
  BORDER_COLOR: [
    'rgb(255, 99, 132)', // 紅色
    'rgb(255, 159, 64)', // 橘色
    'rgb(255, 205, 86)', // 黃色
    'rgb(75, 192, 192)', // 青色
    'rgb(54, 162, 235)', // 藍色
    'rgb(153, 102, 255)', // 紫色
    'rgb(201, 203, 207)' // 灰色
  ]
};

const RENDER_DATA_INFOS = {
  owner: {
    classKey: 'owner_uid',
    unknowKey: 'N/A',
    classAttrs: [
      { key: 'owner_uid', valueKey: 'owner_uid' },
      { key: 'name', valueKey: 'owner_name' },
      { key: 'email', valueKey: 'owner_email' },
      { key: 'company', valueKey: 'sys_organization' }
    ],
    tableKeys: ['name', 'email', 'company', 'running', 'progress20', 'progress40', 'progress55', 'progress57', 'closeWon', 'closeLost', 'sum']
  },
  distributor: {
    classKey: 'sys_organization',
    unknowKey: 'Unorganized',
    classAttrs: [
      { key: 'name', valueKey: 'sys_organization' }
    ],
    tableKeys: ['name', 'running', 'progress20', 'progress40', 'progress55', 'progress57', 'closeWon', 'closeLost', 'sum']
  },
  resellerSales: {
    classKey: 'seller_name',
    unknowKey: 'N/A',
    classAttrs: [
      { key: 'name', valueKey: 'seller_name' },
      { key: 'email', valueKey: 'seller_mail' },
      { key: 'company', valueKey: 'seller_compony_tw' }
    ],
    tableKeys: ['name', 'email', 'company', 'running', 'progress20', 'progress40', 'progress55', 'progress57', 'closeWon', 'closeLost', 'sum']
  },
  reseller: {
    classKey: 'seller_compony_tw',
    unknowKey: 'N/A',
    classAttrs: [
      { key: 'name', valueKey: 'seller_compony_tw' }
    ],
    tableKeys: ['name', 'running', 'progress20', 'progress40', 'progress55', 'progress57', 'closeWon', 'closeLost', 'sum']
  }
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const renderChartData = (focusDeals, order, orderBy, renderInfo) => {
  const json = {};
  focusDeals.forEach((fd) => {
    const key = fd[renderInfo.classKey] || renderInfo.unknowKey;
    if (!json[key]) {
      json[key] = {
        runningRows: [],
        progress20Rows: [],
        progress40Rows: [],
        progress55Rows: [],
        progress57Rows: [],
        closeWonRows: [],
        closeLostRows: []
      };
      renderInfo.classAttrs.forEach((attr) => {
        json[key][attr.key] = fd[attr.valueKey];
      });
      if (key === renderInfo.unknowKey) {
        json[key].name = json[key].name || renderInfo.unknowKey;
      }
    }
    if (fd.status_progress.startsWith('100%') && fd.status_progress.includes('Won')) {
      // close won
      json[key].closeWonRows.push(fd);
    } else if (fd.status_progress.startsWith('100%') && fd.status_progress.includes('Lost')) {
      // close lost
      json[key].closeLostRows.push(fd);
    } else if (!fd.status_progress.startsWith('1%')) {
      // running
      json[key].runningRows.push(fd);
    }

    if (fd.status_progress.startsWith('20%')) {
      json[key].progress20Rows.push(fd);
    } else if (fd.status_progress.startsWith('40%')) {
      json[key].progress40Rows.push(fd);
    } else if (fd.status_progress.startsWith('55%')) {
      json[key].progress55Rows.push(fd);
    } else if (fd.status_progress.startsWith('57%')) {
      json[key].progress57Rows.push(fd);
    }

  });
  const classKeys = Object.keys(json);
  const datas = classKeys.map((cKey) => {
    return {
      ...json[cKey],
      running: json[cKey].runningRows.length,
      progress20: json[cKey].progress20Rows.length,
      progress40: json[cKey].progress40Rows.length,
      progress55: json[cKey].progress55Rows.length,
      progress57: json[cKey].progress57Rows.length,
      closeWon: json[cKey].closeWonRows.length,
      closeLost: json[cKey].closeLostRows.length,
      sum: json[cKey].runningRows.length + json[cKey].closeWonRows.length + json[cKey].closeLostRows.length
    };
  });

  const sortedData = stableSort(datas, getComparator(order, orderBy));
  return sortedData
};

const renderChartTableData = (sortedTableDate, renderInfo) => {
  let runningSum = 0;
  let progress20Sum = 0;
  let progress40Sum = 0;
  let progress55Sum = 0;
  let progress57Sum = 0;
  let closeWonSum = 0;
  let closeLostSum = 0;
  let allSum = 0;

  sortedTableDate.forEach((data) => {
    runningSum += data.running;
    progress20Sum += data.progress20;
    progress40Sum += data.progress40;
    progress55Sum += data.progress55;
    progress57Sum += data.progress57;
    closeWonSum += data.closeWon;
    closeLostSum += data.closeLost;
    allSum += data.sum;
  });

  // const sortedTableDate = stableSort(tableDate, getComparator(order, orderBy));
  const emptyRow = {};
  const sumRow = {};
  renderInfo.tableKeys.forEach((tKey) => {
    emptyRow[tKey] = '';
    sumRow[tKey] = '';
  });
  sortedTableDate.push(emptyRow);
  sortedTableDate.push({
    ...sumRow,
    name: 'Sum',
    running: runningSum,
    progress20: progress20Sum,
    progress40: progress40Sum,
    progress55: progress55Sum,
    progress57: progress57Sum,
    closeWon: closeWonSum,
    closeLost: closeLostSum,
    sum: allSum
  });
  return sortedTableDate;
};

const renderChartDataSet = (sortedate) => {
  // const sortedate = stableSort(datas, getComparator(order, orderBy));
  const labels = sortedate.map((sd) => sd.name);
  const dataSetInfos = [
    { label: 'Running', key: 'running' },
    { label: '20-完成介紹', key: 'progress20' },
    { label: '40-完成POC', key: 'progress40' },
    { label: '55-編列預算中', key: 'progress55' },
    { label: '57-確定預算編列', key: 'progress57' },
    { label: 'Close Won', key: 'closeWon' },
    { label: 'Close Lost', key: 'closeLost' }
  ];
  const datasets = dataSetInfos.map((info, index) => {
    return {
      label: info.label,
      data: sortedate.map((sd) => sd[info.key]),
      borderColor: CHART_CONSTANTS.BORDER_COLOR[index], // 統一顏色
      backgroundColor: CHART_CONSTANTS.BACKGROUND_COLOR[index]
    };
  });
  return { labels, datasets };
};
// owner
const renderOwnerChartData = (focusDeals, order, orderBy) => {
  const datas = renderChartData(focusDeals, order, orderBy, RENDER_DATA_INFOS.owner);
  return datas;
};

const renderOwnerChartTableData = (datas) => {
  const tableDatas = renderChartTableData(datas, RENDER_DATA_INFOS.owner);
  return tableDatas;
};

// Distributor
const renderDistributorChartData = (focusDeals, order, orderBy) => {
  const datas = renderChartData(focusDeals, order, orderBy, RENDER_DATA_INFOS.distributor);
  return datas;
};

const renderDistributorChartTableData = (datas) => {
  const tableDatas = renderChartTableData(datas, RENDER_DATA_INFOS.distributor);
  return tableDatas;
};

// Reseller Sales
const renderSalesChartData = (focusDeals, order, orderBy) => {
  const datas = renderChartData(focusDeals, order, orderBy, RENDER_DATA_INFOS.resellerSales);
  return datas;
};

const renderSalesChartTableData = (datas) => {
  const tableDatas = renderChartTableData(datas, RENDER_DATA_INFOS.resellerSales);
  return tableDatas;
};

// Reseller
const renderResellerChartData = (focusDeals, order, orderBy) => {
  const datas = renderChartData(focusDeals, order, orderBy, RENDER_DATA_INFOS.reseller);
  return datas;
};

const renderResellerChartTableData = (datas) => {
  const tableDatas = renderChartTableData(datas, RENDER_DATA_INFOS.reseller);
  return tableDatas;
};

export {
  getComparator,
  stableSort,
  // common
  renderChartData,
  renderChartTableData,
  renderChartDataSet,
  // Owner
  renderOwnerChartData,
  renderOwnerChartTableData,
  // Distributor
  renderDistributorChartData,
  renderDistributorChartTableData,
  // Reseller Sales
  renderSalesChartData,
  renderSalesChartTableData,
  // Reseller
  renderResellerChartData,
  renderResellerChartTableData
};
