import { CONSTANTS } from "./constants";

const ACCOUNT_INIT_INFO = {
  name: '',
  email: '',
  phone: '',
  compony: '',
  organization: '',
  role: '',
  language: ''
};

export default (state = ACCOUNT_INIT_INFO, action) => {
  switch (action.type) {
    case CONSTANTS.SET_ACCOUNT_INFO:
      const {
        name,
        email,
        phone,
        compony,
        organization,
        role,
        language
      } = action.value;
      return {
        name,
        email,
        phone,
        compony,
        organization,
        role,
        language
      };
    default:
      return state;
  }
}

