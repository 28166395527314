const deduplicateDeals = (deals, typeKeys) => {
  // deduplicate deals by typeKey and keep last one
  if (deals.length === 0) {
    return [];
  }
  const result = [];
  const map = new Map();
  for (let i = deals.length - 1; i > -1 ; i -= 1) {
    // nosemgrep eslint.detect-object-injection -- i is deals index
    const dealObj = deals[i];
    const keys = typeKeys.map((key) => {
      return `${key}_${dealObj[key]}`;
    });
    const key = keys.join('-');
    if (!map.has(key)) {
      map.set(key, true); // set any value to Map
      result.push(dealObj);
    }
  }
  return result;
};

const autoFillEndUser = (deals, userInfo, checkKeys) => {
  const result = deals.filter((dealObj) => {
    return checkKeys.every((key) => {
      if (!userInfo[key]) {
        return true;
      }
      return dealObj[key].includes(userInfo[key]);
    });
  });
  return deduplicateDeals(result, checkKeys);
};

export {
  deduplicateDeals,
  autoFillEndUser
};
