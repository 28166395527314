import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TimerIcon from '@material-ui/icons/Timer';
const CARD_STATUS = ['Waiting'];

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.secondary.dark
  },
  differenceValue: {
    color: theme.palette.secondary.dark,
    marginRight: theme.spacing(1)
  }
}));

const WaitApprove = props => {
  const { className, ...rest } = props;
  const [count, setCount] = useState(0);
  const classes = useStyles();

  useEffect(() => {
    const waitItem = props.dealList.filter((thsDeal) => {
      return CARD_STATUS.includes(thsDeal.case_status);
    });
    setCount(waitItem.length);
  });

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              WaitApprove
            </Typography>
            <Typography variant="h3">{count}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <TimerIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        {/*
        <div className={classes.difference}>
          <ArrowDownwardIcon className={classes.differenceIcon} />
          <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            12%
          </Typography>
          <Typography
            className={classes.caption}
            variant="caption"
          >
            Since last month
          </Typography>
        </div>
        */}
      </CardContent>
    </Card>
  );
};

WaitApprove.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = state => {
  const { dealList } = state;
  return { dealList };
};

export default connect(mapStateToProps)(WaitApprove);
