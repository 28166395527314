import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import XLSX from 'xlsx';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';

import NoteAddIcon from '@material-ui/icons/NoteAdd';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import {
  xlsxRendor_ws, xlsxDownload_ws, ws_to_csv, ws_to_txt, ws_to_html, injectCSS
} from './fileEngine';

const SUPPORTED_FILE_TYPE = [
  { label: 'csv', value: 'csv' },
  { label: 'html', value: 'html' },
  { label: 'xlsx', value: 'xlsx' }
];

const ExportDialog = (props) => {
  const { open, onClose, dealList, onDownload } = props;
  const [fileName, setFileName] = React.useState('');
  const [fileType, setFileType] = React.useState('csv');
  const [formInfoIndex, setFormInfoIndex] = React.useState(0);

  const closeDialog = (event) => {
    onClose();
  };

  const onDownloadFile = () => {
    onDownload(fileName, formInfoIndex, fileType);
    onClose();
  };

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth={true}>
      <DialogTitle id='simple-dialog-title'>
        <Typography variant='h3' style={{ float: 'left' }}>
          <FormattedMessage id={'export_title'}/>
        </Typography>
      </DialogTitle>

      <DialogContent dividers>
        <TextField
          fullWidth
          label={props.intl.formatMessage({ id: 'file_name' })}
          value={fileName}
          onChange={(event) => setFileName(event.target.value)}
        />
        <FormControl fullWidth>
          <label>{props.intl.formatMessage({ id: 'select_data_form' })}</label>
          <Select
            style={{ width: '100%'}}
            id="select_data_form"
            value={formInfoIndex}
            onChange={(event) => {
              setFormInfoIndex(event.target.value);
            }}
            label={props.intl.formatMessage({ id: 'select_data_form' })}
          >
            {
              props.formInfos.map((formInfo, fIndex) => {
                return <MenuItem value={fIndex}>{formInfo.name}</MenuItem>
              })
            }
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <label>{props.intl.formatMessage({ id: 'file_type' })}</label>
          <Select
            style={{ width: '100%'}}
            id="select_file_type"
            value={fileType}
            onChange={(event) => {
              setFileType(event.target.value);
            }}
            label={props.intl.formatMessage({ id: 'file_type' })}
          >
            {
              SUPPORTED_FILE_TYPE.map((item) => {
                return <MenuItem value={item.value}>{item.label}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </DialogContent>
      <div style={{ margin: '15px 30px' }}>
        <Button
          style={{ width: '120px', float: 'right', margin: '0px 5px' }}
          variant='contained'
          color='primary'
          onClick={onDownloadFile}
        >
          <FormattedMessage id={'download'}></FormattedMessage>
        </Button>
        <Button
          style={{ width: '120px', float: 'right', margin: '0px 5px' }}
          variant='outlined'
          color='primary'
          onClick={closeDialog}
        >
          <FormattedMessage id={'cancel'}></FormattedMessage>
        </Button>
      </div>
    </Dialog>
  );
};

ExportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  dealList: PropTypes.string.isRequired
};

const ExcelExportButton = (props) => {
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const downloadTxtFile = (fileName, fileType, fileBody) => {
    const element = document.createElement('a');
    const file = new Blob([fileBody], {type: `application/${fileType}`});
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}.${fileType}`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const onDownload = (fileName, formInfoIndex, fileType) => {
    const ws = xlsxRendor_ws(props.intl, props.formInfos[formInfoIndex], props.dealList);
    const myFileName = fileName || `DealExport${new Date().getTime()}`;
    if (fileType === 'csv') {
      const csvFile = ws_to_csv(ws);
      downloadTxtFile(myFileName, fileType, csvFile);
    } else if (fileType === 'xlsx') {
      xlsxDownload_ws(ws, `${myFileName}.xlsx`, 'Sheet1');
    } else if (fileType === 'html') {
      const htmlFile = injectCSS(ws_to_html(ws));
      downloadTxtFile(myFileName, fileType, htmlFile);
    }
  };

  return (
    <div>
      <Tooltip title={props.intl.formatMessage({ id: 'export' })}>
        <IconButton aria-label="Export" onClick={() => setDialogOpen(true)}>
          <CloudDownloadIcon fontSize="large" color="primary"/>
        </IconButton>
      </Tooltip>
      <ExportDialog onDownload={onDownload} open={dialogOpen} onClose={() => setDialogOpen(false)} {...props}/>
    </div>
  );
};

ExcelExportButton.propTypes = {
  formInfos: PropTypes.array.isRequired,
  dealList: PropTypes.string.isRequired
};

export default injectIntl(ExcelExportButton);
