import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton, Tooltip } from '@material-ui/core';
import { useAuth0 } from "@auth0/auth0-react";
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import envConfig from '../../../../configuration/env.json';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const { logout } = useAuth0();
  const classes = useStyles();

  const [notifications] = useState([]);

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink to="/">
          <h2 style={{ color: '#ffffff'}}>
            {envConfig.name}
          </h2>
          {/*
          <img
            alt="Logo"
            src="/images/logos/logo--white.svg"
          />
          */}
        </RouterLink>
        <div className={classes.flexGrow} />
        {/*
        <Hidden mdDown>
          {/********************************
          <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          ******************************** /}
          <Tooltip title="Logout" aria-label="Logout">
            <IconButton
              className={classes.signOutButton}
              onClick={() => logout()}
              color="inherit"
            >
              <InputIcon />
            </IconButton>
          </Tooltip>
        </Hidden>
        */}
        {/*<Hidden lgUp>*/}
        <IconButton
          color="inherit"
          onClick={onSidebarOpen}
        >
          <MenuIcon />
        </IconButton>
        {/*</Hidden>*/}
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
