import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import DateFnsUtils from "@date-io/date-fns";
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ClearIcon from '@material-ui/icons/Clear';
import ArrowLeftOutlinedIcon from '@material-ui/icons/ArrowLeftOutlined';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import { HorizontalBar } from 'react-chartjs-2';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { setAuthInfo, setDealList, setLoading, setAccountInfo, setNotifyRule, setOrgMember } from '../../actions'
import FilterComponent from '../../components/FilterComponentsV2';
import { filterEngine } from '../../components/FilterComponentsV2/filterLib';
import DataViewer from './DataViewer'
import { PickSelect } from '../../components/CustComponents'

import {
  renderChartDataSet,
  // Owner
  renderOwnerChartData,
  renderOwnerChartTableData,
  // Distributor
  renderDistributorChartData,
  renderDistributorChartTableData,
  // Reseller Sales
  renderSalesChartData,
  renderSalesChartTableData,
  // Reseller
  renderResellerChartData,
  renderResellerChartTableData
} from './analyzUtil';

import analyzFilterInfo from '../../configuration/analyzFilterInfo.json';

const FILTER_ACCORDION_STR = 'accordion';
const FILTER_FLATTEN_STR = 'flatten';
const FILTER_DIALOG_STR = 'dialog';

const MOBILE_SCREEN_STR = 'mobile';
const MEDIUM_SCREEN_STR = 'medium';
const LARGE_SCREEN_STR = 'large';

const SCREEN_SIZE_INFO_MAP = {
  [MOBILE_SCREEN_STR]: {
    filterMode: FILTER_DIALOG_STR,
    tools: {
      refresh: true,
      create: true,
      excelImport: false,
      excelExport: true,
      filter: true,
    }
  },
  [MEDIUM_SCREEN_STR]: {
    filterMode: FILTER_ACCORDION_STR,
    tools: {
      refresh: true,
      create: true,
      excelImport: true,
      excelExport: true,
      filter: true,
    }
  },
  [LARGE_SCREEN_STR]: {
    filterMode: FILTER_FLATTEN_STR,
    tools: {
      refresh: true,
      create: true,
      excelImport: true,
      excelExport: true,
      filter: true,
    }
  }
};

const TABLE_HEADERS = [
  { "sort": true, "label": "name", "label_id": "name", "key": "name", "tooltipKey": "email", "headerStyle": { "width": "20%" }, "style": { "width": "20%", "height": "34px" }, "align": "left" },
  { "sort": true, "label": "company", "label_id": "company", "key": "company", "tooltipKey": "", "headerStyle": { "width": "20%" }, "style": { "width": "20%", "height": "34px" }, "align": "left" },
  { "sort": true, "label": "running", "label_id": "running", "key": "running", "tooltipKey": "", "headerStyle": { "width": "15%" }, "style": { "width": "15%", "height": "34px" }, "align": "center" },
  { "sort": true, "label": "20%", "label_id": "progress_20", "key": "progress20", "tooltipKey": "", "headerStyle": { "width": "15%" }, "style": { "width": "15%", "height": "34px" }, "align": "center" },
  { "sort": true, "label": "40%", "label_id": "progress_40", "key": "progress40", "tooltipKey": "", "headerStyle": { "width": "15%" }, "style": { "width": "15%", "height": "34px" }, "align": "center" },
  { "sort": true, "label": "55%", "label_id": "progress_55", "key": "progress55", "tooltipKey": "", "headerStyle": { "width": "15%" }, "style": { "width": "15%", "height": "34px" }, "align": "center" },
  { "sort": true, "label": "57%", "label_id": "progress_57", "key": "progress57", "tooltipKey": "", "headerStyle": { "width": "15%" }, "style": { "width": "15%", "height": "34px" }, "align": "center" },
  { "sort": true, "label": "closeWon", "label_id": "close_won", "key": "closeWon", "tooltipKey": "", "headerStyle": { "width": "15%" }, "style": { "width": "15%", "height": "34px" }, "align": "center" },
  { "sort": true, "label": "closeLost", "label_id": "close_lost", "key": "closeLost", "tooltipKey": "", "headerStyle": { "width": "15%" }, "style": { "width": "15%", "height": "34px" }, "align": "center" },
  { "sort": true, "label": "sum", "label_id": "sum", "key": "sum", "tooltipKey": "", "headerStyle": { "width": "15%" }, "style": { "width": "15%", "height": "34px" }, "align": "center" }
];

const ORG_TABLE_HEADERS = [
  { "sort": true, "label": "name", "label_id": "name", "key": "name", "tooltipKey": "", "headerStyle": { "width": "40%" }, "style": { "width": "40%", "height": "34px" }, "align": "left" },
  { "sort": true, "label": "running", "label_id": "running", "key": "running", "tooltipKey": "", "headerStyle": { "width": "15%" }, "style": { "width": "15%", "height": "34px" }, "align": "center" },
  { "sort": true, "label": "20%", "label_id": "progress_20", "key": "progress20", "tooltipKey": "", "headerStyle": { "width": "15%" }, "style": { "width": "15%", "height": "34px" }, "align": "center" },
  { "sort": true, "label": "40%", "label_id": "progress_40", "key": "progress40", "tooltipKey": "", "headerStyle": { "width": "15%" }, "style": { "width": "15%", "height": "34px" }, "align": "center" },
  { "sort": true, "label": "55%", "label_id": "progress_55", "key": "progress55", "tooltipKey": "", "headerStyle": { "width": "15%" }, "style": { "width": "15%", "height": "34px" }, "align": "center" },
  { "sort": true, "label": "57%", "label_id": "progress_57", "key": "progress57", "tooltipKey": "", "headerStyle": { "width": "15%" }, "style": { "width": "15%", "height": "34px" }, "align": "center" },
  { "sort": true, "label": "closeWon", "label_id": "close_won", "key": "closeWon", "tooltipKey": "", "headerStyle": { "width": "15%" }, "style": { "width": "15%", "height": "34px" }, "align": "center" },
  { "sort": true, "label": "closeLost", "label_id": "close_lost", "key": "closeLost", "tooltipKey": "", "headerStyle": { "width": "15%" }, "style": { "width": "15%", "height": "34px" }, "align": "center" },
  { "sort": true, "label": "sum", "label_id": "sum", "key": "sum", "tooltipKey": "", "headerStyle": { "width": "15%" }, "style": { "width": "15%", "height": "34px" }, "align": "center" }
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 350,
  },
  shineRow: {
    '&:hover': {
      backgroundColor: '#DDDDDD !important'
    },
    background: '#3dabe248',
  },
  warnRow: {
    '&:hover': {
      backgroundColor: '#DDDDDD !important'
    },
    background: '#FFD0D0'
  },
  calmRow: {
    '&:hover': {
      backgroundColor: '#DDDDDD !important'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const initFilterDate = () => {
  const currentDate = new Date();
  const halfYearAgo = new Date(currentDate.setMonth(currentDate.getMonth() - 6));
  halfYearAgo.setDate(1);
  return halfYearAgo;
};

const Analyz = (props) => {
  const classes = useStyles();
  const [orgOption, setOrgOption] = React.useState([]);
  const [filterForm, setFilterForm] = React.useState({
    caseApprovalDateLeft: initFilterDate()
  });
  const [ownerOrder, setOwnerOrder] = React.useState('desc'); // asc/desc
  const [ownerOrderBy, setOwnerOrderBy] = React.useState('sum');
  const [distributorOrder, setDistributorOrder] = React.useState('desc');
  const [distributorOrderBy, setDistributorOrderBy] = React.useState('sum');
  const [salesOrder, setSalesOrder] = React.useState('desc');
  const [salesOrderBy, setSalesOrderBy] = React.useState('sum');
  const [resellerOrder, setResellerOrder] = React.useState('desc');
  const [resellerOrderBy, setResellerOrderBy] = React.useState('sum');
  const [topNum, setTopNum] = React.useState(20);

  useEffect(() => {
    const orgSet = new Set([]);
    props.orgMember.forEach((om) => {
      if (om.organization) {
        orgSet.add(om.organization);
      }
    });
    const orgArray = Array.from(orgSet);
    setOrgOption(orgArray);
  }, [props.orgMember.length]);

  const onFilterChange = (key, value) => {
    const obj = JSON.parse(JSON.stringify(filterForm));
    obj[key] = value;
    setFilterForm(obj);
  };
  const onFilterWHChange = (width, height) => {
    // console.log({ width, height });
  };

  const onOwnerSortChange = (property) => (event) => {
    const isAsc = ownerOrderBy === property && ownerOrder === 'asc';
    setOwnerOrder(isAsc ? 'desc' : 'asc');
    setOwnerOrderBy(property);
  };

  const onDistributorSortChange = (property) => (event) => {
    const isAsc = distributorOrderBy === property && distributorOrder === 'asc';
    setDistributorOrder(isAsc ? 'desc' : 'asc');
    setDistributorOrderBy(property);
  };

  const onResellerSalesSortChange = (property) => (event) => {
    const isAsc = salesOrderBy === property && salesOrder === 'asc';
    setSalesOrder(isAsc ? 'desc' : 'asc');
    setSalesOrderBy(property);
  };

  const onResellerSortChange = (property) => (event) => {
    const isAsc = resellerOrderBy === property && resellerOrder === 'asc';
    setResellerOrder(isAsc ? 'desc' : 'asc');
    setResellerOrderBy(property);
  };

  const focusDeals = filterEngine(props.sheetDataForm, props.dealList, analyzFilterInfo, filterForm);
  // user
  const ownerChartData = renderOwnerChartData(focusDeals, ownerOrder, ownerOrderBy).slice(0, topNum);
  const ownerChartDataSet = renderChartDataSet(ownerChartData);
  const ownerChartTableData = renderOwnerChartTableData(ownerChartData);
  // Distributor
  const distributorChartData = renderDistributorChartData(focusDeals, distributorOrder, distributorOrderBy).slice(0, topNum);
  const distributorChartDataSet = renderChartDataSet(distributorChartData);
  const distributorChartTableData = renderDistributorChartTableData(distributorChartData);
  // Reseller Sales
  const salesChartData = renderSalesChartData(focusDeals, salesOrder, salesOrderBy).slice(0, topNum);
  const salesChartDataSet = renderChartDataSet(salesChartData);
  // TODO: display company
  const salesChartTableData = renderSalesChartTableData(salesChartData);
  // Reseller
  const resellerChartData = renderResellerChartData(focusDeals, resellerOrder, resellerOrderBy).slice(0, topNum);
  const resellerChartDataSet = renderChartDataSet(resellerChartData);
  const resellerChartTableData = renderResellerChartTableData(resellerChartData);

  return (
    <>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root} style={{ width: '100%' }} >
          <Typography className={classes.title} style={{fontSize:'30px'}} variant="h6" id="tableTitle" component="div">
            Analyz
          </Typography>
          <div style={{ width: '100%' }}>
            <div style={{ float: 'right' }}>
              <FilterComponent
                display={SCREEN_SIZE_INFO_MAP[props.screen].filterMode}
                filterForm={filterForm}
                filterInfos={analyzFilterInfo}
                onFilterFormChange={onFilterChange}
                onFilterWHChange={onFilterWHChange}
              >
                <PickSelect
                  enable={true}
                  style={{ width: '100%' }}
                  title={props.intl.formatMessage({ id: 'top' })}
                  value={topNum}
                  options={[
                    { label: '10', value: 10 },
                    { label: '20', value: 20 },
                    { label: '30', value: 30 },
                    { label: '50', value: 50 }
                  ]}
                  onChange={(event) => {
                    setTopNum(0);
                    setTimeout(() => {
                      setTopNum(event.target.value);
                    }, 500);
                  }}
                />
              </FilterComponent>
            </div>
          </div>
        </Toolbar>
        <DataViewer
          title={`Owner Analyz - Top ${topNum}`}
          tableHeaders={TABLE_HEADERS}
          tableData={ownerChartTableData}
          chartData={ownerChartDataSet}
          order={ownerOrder}
          orderBy={ownerOrderBy}
          onSortChange={onOwnerSortChange}
        />
        {
          props.account.role === 'admin' &&
          <>
            <Divider style={{ margin: '20px 0px' }}/>
            <DataViewer
              title={`Distributor Analyz - Top ${topNum}`}
              tableHeaders={ORG_TABLE_HEADERS}
              tableData={distributorChartTableData}
              chartData={distributorChartDataSet}
              order={distributorOrder}
              orderBy={distributorOrderBy}
              onSortChange={onDistributorSortChange}
            />
            <Divider style={{ margin: '20px 0px' }}/>
            <DataViewer
              title={`Reseller Sales Analyz - Top ${topNum}`}
              tableHeaders={TABLE_HEADERS}
              tableData={salesChartTableData}
              chartData={salesChartDataSet}
              order={salesOrder}
              orderBy={salesOrderBy}
              onSortChange={onResellerSalesSortChange}
            />
            <Divider style={{ margin: '20px 0px' }}/>
            <DataViewer
              title={`Reseller Analyz - Top ${topNum}`}
              tableHeaders={ORG_TABLE_HEADERS}
              tableData={resellerChartTableData}
              chartData={resellerChartDataSet}
              order={resellerOrder}
              orderBy={resellerOrderBy}
              onSortChange={onResellerSortChange}
            />
          </>
        }
      </Paper>
    </>
  );
};

const mapStateToProps = state => {
  const { dealList, loading, orgMember, account, screen, sheetDataForm } = state;
  return { dealList, loading, orgMember, account, screen, sheetDataForm };
};

export default connect(mapStateToProps, {})(withAuthenticationRequired(injectIntl(Analyz)));
