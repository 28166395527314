import { CONSTANTS } from "./constants";

const ORG_MEMBER = [];

export default (state = ORG_MEMBER, action) => {
  switch (action.type) {
    case CONSTANTS.SET_ORG_MEMBER :
      return JSON.parse(JSON.stringify(action.value));

    default:
      return state;
  }
}
