import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField'
import { SearchInput } from 'components';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: '1 1 100%',
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  focusPaper: {
    borderRadius: '4px',
    alignItems: 'center',
    // padding: theme.spacing(1),
    display: 'flex'
  }
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        <FormattedMessage id='user_manager'/>
      </Typography>
      <Paper className={classes.focusPaper}>
        <FormControl style={{ minWidth: "130px", margin: '0px 10px' }}>
          <InputLabel id="focus-orgnization-select-label" shrink={true}>
            <FormattedMessage id="orgnization"/>
          </InputLabel>
          
          <Select
            labelId="focus-orgnization-select-label"
            displayEmpty={true}
            style={{ width: '100%'}}
            id='focus-orgnization-select'
            disableUnderline
            label={props.intl.formatMessage({ id: 'orgnization' })}
            value={props.focusOrg}
            onChange={props.onFocusOrgChange}
          >
            <MenuItem value=''>All</MenuItem>
            {
              props.focusOrgOption.map((focusOrgStr) => {
                return <MenuItem value={focusOrgStr}>{focusOrgStr}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </Paper>
      <Tooltip title={props.intl.formatMessage({ id: 'search' })} style={{marginLeft:'1rem'}}>
        <SearchInput
          className={classes.searchInput}
          placeholder={props.intl.formatMessage({ id: 'search_msg' })}
          onChange={props.onFilterChange}
          value={props.onFilterValue}
        />
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  onFilterValue: PropTypes.string.isRequired,
  focusOrg: PropTypes.string.isRequired,
  onFocusOrgChange: PropTypes.string.isRequired,
  focusOrgOption: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default injectIntl(EnhancedTableToolbar);
