import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import envConfig from '../../configuration/env.json';

const LoginOutButton = () => {
  const { loginWithRedirect, loginWithPopup, logout, isAuthenticated } = useAuth0();
  // return <button onClick={() => loginWithRedirect()}>Log In</button>;
  return (isAuthenticated) ? (
    <button onClick={() => {
      return logout();
    }}>Log Out</button>
  ) : (
    <button onClick={() => {
      return loginWithPopup(); // loginWithRedirect();
    }}>Log In</button>
  );
};

export default LoginOutButton;