import { CONSTANTS } from "./constants";

const ORDER_BY = 'user_compony_tw';

export default (state = ORDER_BY, action) => {
  switch (action.type) {
    case CONSTANTS.SET_ORDER_BY :
      return action.value;

    default:
      return state;
  }
}
