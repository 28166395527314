import React, { useEffect } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { HorizontalBar } from 'react-chartjs-2';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 350,
  },
  shineRow: {
    '&:hover': {
      backgroundColor: '#DDDDDD !important'
    },
    background: '#3dabe248',
  },
  warnRow: {
    '&:hover': {
      backgroundColor: '#DDDDDD !important'
    },
    background: '#FFD0D0'
  },
  calmRow: {
    '&:hover': {
      backgroundColor: '#DDDDDD !important'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

const DataViewer = (props) => {
  const classes = useStyles();
  return (
    <>
      <Toolbar className={classes.root} >
        <Typography className={classes.title} style={{fontSize:'30px'}} variant="h6" id="tableTitle" component="div">
          {props.title}
        </Typography>
      </Toolbar>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  {
                    props.tableHeaders.map((headerObj) => {
                      if (!headerObj.sort) {
                        return (
                          <TableCell style={headerObj.headerStyle} align='center'>
                            <FormattedMessage id={headerObj.label_id} defaultMessage={headerObj.label}/>
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell style={headerObj.headerStyle} align='center'>
                          <TableSortLabel
                            active={props.orderBy === headerObj.key}
                            direction={props.orderBy === headerObj.key ? props.order : 'asc'}
                            onClick={props.onSortChange(headerObj.key)}
                          >
                            <FormattedMessage id={headerObj.label_id} defaultMessage={headerObj.label}/>
                          </TableSortLabel>
                        </TableCell>
                      );
                    })
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  props.tableData.map((tableData) => {
                    return (
                      <TableRow>
                        {
                          props.tableHeaders.map((headerObj) => {
                            if (headerObj.tooltipKey) {
                              return (
                                <Tooltip title={tableData[headerObj.tooltipKey]}>
                                  <TableCell align={headerObj.align} style={headerObj.style}>{tableData[headerObj.key]}</TableCell>
                                </Tooltip>
                              );
                            }
                            return (
                              <TableCell align={headerObj.align} style={headerObj.style}>{tableData[headerObj.key]}</TableCell>
                            );
                          })
                        }
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          {
            (props.tableData.length < 3 ) ? <div>Loading...</div> :
            <HorizontalBar 	height={null} width={null} data={props.chartData} options={{
              maintainAspectRatio: false,
              scales: {
                xAxes: [{
                  display: true,
                  ticks: {
                      // suggestedMin: 0,    // minimum will be 0, unless there is a lower value.
                      // OR //
                      beginAtZero: true   // minimum value will be 0.
                  }
                }]
              }
            }}/>
          }
        </Grid>
      </Grid>
    </>
  );
};

export default injectIntl(DataViewer);
