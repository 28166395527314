import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

const ReqViewer = (props) => {
  const reqParams = props.requestInfo ? props.requestInfo.params || {} : {};
  const reqQuery = props.requestInfo ? props.requestInfo.query || {} : {};
  const reqBody = props.requestInfo ? props.requestInfo.body || {} : {};

  return (
    <>
      <TextField
        label='Params'
        fullWidth
        multiline
        disabled
        value={JSON.stringify(reqParams, null, 2)}
      />
      <TextField
        label='Query'
        fullWidth
        multiline
        disabled
        value={JSON.stringify(reqQuery, null, 2)}
      />
      <TextField
        label='Body'
        fullWidth
        multiline
        disabled
        value={JSON.stringify(reqBody, null, 2)}
      />
    </>
  );
};

ReqViewer.propTypes = {
  requestInfo: PropTypes.object
};

export default ReqViewer;