import { CONSTANTS } from "./constants";

const DEAL_LIST = [];

export default (state = DEAL_LIST, action) => {
  switch (action.type) {
    case CONSTANTS.SET_DEAL_LIST :
      return JSON.parse(JSON.stringify(action.value));

    default:
      return state;
  }
}
