import { CONSTANTS } from "./constants";

const AUTH_INIT_INFO = {
  sub: '',
  given_name: '',
  nickname: '',
  name: '',
  picture: '',
  locale: '',
  updated_at: '',
  email: '',
  email_verified: true,
  accessToken: ''
};

export default (state = AUTH_INIT_INFO, action) => {
  switch (action.type) {
    case CONSTANTS.SET_AUTH_INFO :
      const {
        sub,
        given_name,
        nickname,
        name,
        picture,
        locale,
        updated_at,
        email,
        email_verified,
        accessToken
      } = action.value;
      return {
        sub,
        given_name,
        nickname,
        name,
        picture,
        locale,
        updated_at,
        email,
        email_verified,
        accessToken
      };
    default:
      return state;
  }
}

