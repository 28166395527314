import palette from 'theme/palette';
import moment from 'moment';
moment().format();

const MONTHS = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec'];

const getMonthRange = (range) => {
  const now = new Date();
  const nowTime = now.getTime();
  let nowMonth = now.getMonth();
  let nowYear = now.getFullYear();
  if (nowMonth - range < 0) {
    nowMonth = nowMonth + 12;
    nowYear = nowYear - 1;
  }
  const targetTime = new Date().setFullYear(nowYear).setMonth(nowMonth).setDate(1);
};

export const formatChartData = (dealList) => {
  const now = new Date();
  const nowTime = now.getTime();

  const a = moment().subtract(1, 'months');
  dealList.forEach((deal) => {
    const dealTime = new Date(deal.sys_last_update_stamp).getTime();
    // console.log({ dealTime });
  });
  

  // agent_DR_Number: ""
  // agent_name: "eccccc"
  // case_book_code: ""
  // case_status: "Waiting"
  // product_detail: ""
  // product_price: "1234567tre"
  // proposal_name: ""
  // proposal_number: ""
  // seller_compony_en: ""
  // seller_compony_tw: ""
  // seller_mail: ""
  // seller_name: "hello"
  // seller_phone: ""
  // status_estimatedClosing: ""
  // status_progress: "80% - 經銷商取得訂單"
  // status_update: "今天吃烤肉！"
  // sys_case_uid: "1eg5a8amu"
  // sys_create_stamp: "2020/8/20 14:52:45"
  // sys_last_update_stamp: "2020/8/21 11:01:36"
  // sys_organization: "hello"
  // user_compony_en: "qwert"
  // user_compony_tw: "qwert"
  // user_extend: "qwert"
  // user_mail: ""
  // user_name: "qwert"
  // user_phone: ""
  // user_tax_id: "qwert"
  // user_work_phone: "qwert"
};

export const data = {
  labels: ['0 Aug', '2 Aug', '3 Aug', '4 Aug', '5 Aug', '6 Aug'],
  datasets: [
    {
      label: 'WaitApprove',
      backgroundColor: palette.secondary.main,
      data: [18, 5, 19, 27, 29, 19, 20]
    },
    {
      label: 'Approved',
      backgroundColor: palette.primary.main,
      data: [11, 20, 12, 29, 30, 25, 13]
    },
    {
      label: 'Expired',
      backgroundColor: palette.warning.main,
      data: [20, 12, 29, 30, 25, 13, 11]
    },
    {
      label: 'Closed',
      backgroundColor: palette.text.main,
      data: [12, 29, 30, 25, 13, 11, 20]
    }
  ]
};

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  legend: { display: false },
  cornerRadius: 20,
  tooltips: {
    enabled: true,
    mode: 'index',
    intersect: false,
    borderWidth: 1,
    borderColor: palette.divider,
    backgroundColor: palette.white,
    titleFontColor: palette.text.primary,
    bodyFontColor: palette.text.secondary,
    footerFontColor: palette.text.secondary
  },
  layout: { padding: 0 },
  scales: {
    xAxes: [
      {
        barThickness: 12,
        maxBarThickness: 10,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        ticks: {
          fontColor: palette.text.secondary
        },
        gridLines: {
          display: false,
          drawBorder: false
        }
      }
    ],
    yAxes: [
      {
        ticks: {
          fontColor: palette.text.secondary,
          beginAtZero: true,
          min: 0
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: palette.divider
        }
      }
    ]
  }
};
