import { CONSTANTS } from "./constants";

const MEDIUM_WIDTH = 950;
const MOBILE_WIDTH = 768;

const getScreenSize = () => {
  if (window.innerWidth < MOBILE_WIDTH) {
    return 'mobile';
  } else if (window.innerWidth < MEDIUM_WIDTH) {
    return 'medium';
  } else {
    return 'large';
  }
};

const INIT_SCREEN = getScreenSize();

export default (state = INIT_SCREEN, action) => {
  switch (action.type) {
    case CONSTANTS.SET_SCREEN :
      return action.value;
    default:
      return state;
  }
}
