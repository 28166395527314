export const CONSTANTS = {
  SET_ACCOUNT_INFO: 'set_account_info',
  SET_AUTH_INFO: 'set_auth_info',
  SET_DEAL_LIST: 'set_deal_list',
  SET_LOADING: 'set_loading',
  SET_NOTIFY_RULE: 'set_notify_rule',
  SET_ORG_MEMBER: 'set_or_member',
  // SET_FILTER_STR: 'set_filter_str',
  // SET_STATUS_FILTER: 'set_status_filter',
  // SET_UPDATE_RANGE_FILTER: 'set_update_range_filter',
  // SET_PRODUCT_TYPE_FILTER: 'set_product_type_filter',
  // SET_PROGRESS_FILTER: 'set_progress_filter',
  // SET_ESTIMATED_CLOSING_FILTER: 'set_estimated_closing_filter',
  SET_FILTER_INFO: 'set_filter_info',
  SET_ORDER: 'set_order',
  SET_ORDER_BY: 'set_order_by',
  // SET_FILTER_OWNER: 'set_filter_owner',
  // SET_GREEDY_FILTER_STR: 'set_greedy_filter_str',
  SET_SHEET_DATA_FORM: "set_sheet_data_form",
  // SET_FILTER_DATA_FORM: 'set_filter_data_form',
  SET_FORM_INFOS: 'set_form_infos',
  SET_SCREEN: 'set_screen',
  SET_TASK_LOG: 'set_task_log',
  SET_TASK_LOG_PAGE: 'set_task_log_page'
};
