import React, { Component, useState } from "react";
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import { confirmAlert } from 'react-confirm-alert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RefreshIcon from '@material-ui/icons/Refresh';

import ReqViewer from './ReqViewer';

import { httpRequest } from '../../lib';

import 'react-confirm-alert/src/react-confirm-alert.css';

const VIEW_VALUE_STYLE = {
  padding: '0px 10px',
  color: '#23a455'
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const renderQueryParams = (jsonQuery) => {
  if (!jsonQuery) {
    return '';
  }
  const keys = Object.keys(jsonQuery);
  if (keys.length === 0) {
    return '';
  }
  const queryParamStr = keys.map((key) => {
    return `${key}=${encodeURI(jsonQuery[key])}`;
  }).join('&');
  return `?${queryParamStr}`;
};

const renderRetryUrl = (params, query) => {
  const { dataForm, case_id: caseID } = params;
  if (caseID) {
    return `${dataForm}/${caseID}${renderQueryParams(query)}`;
  }
  return `${dataForm}${renderQueryParams(query)}`;
};

const TaskLogDialog = (props) => {
  const classes = useStyles();
  const onCloseTaskLogDialog = (event) => {
    props.onClose();
  };

  const onRetryTask = (event) => {
    const ALLOW_OPERATE = ['update_row', 'add_rows'];
    if (ALLOW_OPERATE.includes(props.taskLog.operate)) {
      const { params, query, body } = props.taskLog.requestInfo;
      const url = `/api/dealtrack/rows/${renderRetryUrl(params, query)}`;
      // reload
      props.onLoading(true);
      httpRequest(props.taskLog.method, url, body, props.auth.accessToken, (statusCode, body) => {
        props.onLoading(false);
        props.onClose();
        confirmAlert({
          title: 'Message',
          message: body.message,
          closeOnClickOutside: false,
          buttons: [{ label: 'Confirm' }]
        });
      });
    } else {
      props.onClose();
      confirmAlert({
        title: 'Warning',
        message: props.intl.formatMessage({ id: 'not_support_retry_type' }),
        closeOnClickOutside: false,
        buttons: [{ label: 'Confirm' }]
      });
    }
  };

  return (
    <Dialog
      maxWidth='md'
      open={props.open}
      onClose={onCloseTaskLogDialog}
      aria-labelledby="task-log-dialog-title"
      aria-describedby="task-log-dialog-description"
    >
      <DialogTitle style={{ minWidth: '600px'}} id="task-log-dialog-title">
        <FormattedMessage id="task_log_title" />
      </DialogTitle>
      <DialogContent dividers>
        {
          props.taskLog &&
          <table>
            <tbody>
              <tr>
                <td><FormattedMessage id="operate" /></td>
                <td style={VIEW_VALUE_STYLE}>
                  <FormattedMessage id={`operate_${props.taskLog.operate || 'unknown'}`} />
                </td>
              </tr>
              {
                props.taskLog.resultData ?
                <>
                  <tr>
                    <td><FormattedMessage id="task_log_message" /></td>
                    <td style={VIEW_VALUE_STYLE}>{props.taskLog.resultData.message}</td>
                  </tr>
                  {
                    !props.taskLog.resultData.status &&
                    <>
                      <tr>
                        <td><FormattedMessage id="task_log_err" /></td>
                        <td style={VIEW_VALUE_STYLE}>{props.taskLog.resultData.errMsg}</td>
                      </tr>
                    </>
                  }
                </> : <>
                  <tr>
                    <td><FormattedMessage id="task_log_message" /></td>
                    <td style={VIEW_VALUE_STYLE}><FormattedMessage id="processing" /></td>
                  </tr>
                </>
              }
              <tr>
                <td><FormattedMessage id="owner" /></td>
                <td style={VIEW_VALUE_STYLE}>{props.taskLog.userName || 'N/A'}</td>
              </tr>
              <tr>
                <td><FormattedMessage id="organization" /></td>
                <td style={VIEW_VALUE_STYLE}>{props.taskLog.org || 'N/A'}</td>
              </tr>
              <tr>
                <td><FormattedMessage id="req_time" /></td>
                <td style={VIEW_VALUE_STYLE}>{props.taskLog.reqTime}</td>
              </tr>
              <tr>
                <td><FormattedMessage id="end_time" /></td>
                <td style={VIEW_VALUE_STYLE}>{props.taskLog.endTime || 'N/A'}</td>
              </tr>
              {
                props.taskLog.resultData && !props.taskLog.resultData.status &&
                <tr>
                  <td><FormattedMessage id="retry" /></td>
                  <td style={{ padding: '0px 10px;' }}>
                    <IconButton onClick={onRetryTask} variant="contained" size='small'>
                      <RefreshIcon />
                    </IconButton>
                  </td>
                </tr>
              }
              <tr>
                <td><FormattedMessage id="task_log_req_data" /></td>
                <td>
                  <Accordion style={{ maxWidth: '500px' }}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.heading}><FormattedMessage id="detail" /></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography style={{ lineBreak: 'anywhere'}}>
                        <ReqViewer requestInfo={props.taskLog.requestInfo} />
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </td>
              </tr>
            </tbody>
          </table>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseTaskLogDialog} variant="contained">
          <FormattedMessage id="close" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

TaskLogDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  taskLog: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  onLoading: PropTypes.func.isRequired
};

export default injectIntl(TaskLogDialog);
