import { CONSTANTS } from "./constants";

const initFormInfos = [{
  name: 'None',
  layout: {
    initUseAttr: {},
    classes: [{
      title_id: 'Loading...',
      attr: []
    }]
  },
  map: []
}];

export default (state = initFormInfos, action) => {
  switch (action.type) {
    case CONSTANTS.SET_FORM_INFOS:
      return action.value;
    default:
      return state;
  }
}
