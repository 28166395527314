import React, { useState } from 'react';
import ReactDataSheet from 'react-datasheet';
// import detailMap from '../../../configuration/attMap.json';
import '../../../assets/scss/xlsxTable.scss';

const ENTER_KEY = 13;
const TAB_KEY = 9;

const custHandleCopy = (event) => {
  const startRange = { i: Math.min(event.start.i, event.end.i), j: Math.min(event.start.j, event.end.j) };
  const endRange = { i: Math.max(event.start.i, event.end.i), j: Math.max(event.start.j, event.end.j) };

  const board = event.data.slice(startRange.i, endRange.i + 1).map((cols) => {
    return cols.slice(startRange.j, endRange.j + 1);
  }).map((cols) => {
    return cols.map((colValue) => {
      return (colValue.value.includes('\n')) ? `"${colValue.value}"` : colValue.value;
    }).join('\t');
  }).join('\n');
  event.event.clipboardData.setData('text/plain', board);
};

const custParser = (str) => {
  const r1 = str.split(/\r\n|\n|\r/).map(function (row) {
    return row.split('\t');
  });
  const rows = [];
  let regCols = [];
  let regStr = '';
  for (let i = 0; i < r1.length; i += 1) {
    for (let j = 0; j < r1[i].length; j += 1) {
      if (!regStr && r1[i][j].replace(/\"\"/g, '').startsWith('"')) {
        if (r1[i][j].replace(/\"\"/g, '').endsWith('"')) {
          regCols.push(r1[i][j].replace(/\"\"/g, '"').slice(1, -1));
          continue;
        }
        // nosemgrep eslint.detect-object-injection -- i, j is r1 index
        regStr = r1[i][j];
        continue;
      }
      if (regStr) {
        regStr += `\n${r1[i][j]}`;
      } else {
        regCols.push(r1[i][j]);
      }
      if (regStr && r1[i][j].replace(/\"\"/g, '').endsWith('"')) {
        regCols.push(regStr.replace(/\"\"/g, '"').slice(1, -1));
        regStr = '';
      }
    }
    if (!regStr) {
      rows.push(regCols);
      regCols = [];
    }
  }
  return rows;
};

const TextAreaInput = (props) => {
  const { value, onKeyDown, onChange } = props;
  const lines = value.split('\n');
  const maxTextLength = lines.reduce((preLine, line) => {
    return (line.length > preLine.length) ? line : preLine;
  }, '').length;
  return (
    <textarea
      autoFocus
      rows={lines.length + 1}
      cols={maxTextLength}
      value={value}
      onChange={event => onChange(event.target.value)}
      onKeyDown={(event) => {
        const keyCode = event.which || event.keyCode;
        const ctrlKey = event.ctrlKey;
        if (keyCode === ENTER_KEY) {
          if (ctrlKey) {
            onChange(`${value}\n`);
            return;
          }
          if (value.length !== event.target.selectionStart) {
            return;
          }
        }
        onKeyDown(event);
      }}
    />
  );
};

const keepEmptyRows = (props) => {
  const BASIC_ROW_NUMBER = 5;
  const BASIC_EMPTY_NUMBER = 3;
  const { grid, setGrid } = props;
  const gridLength = grid.length;

  let appendNumber = 0;
  let currentEmptyNumber = 0;

  if (gridLength < BASIC_ROW_NUMBER + 1) {
    appendNumber = BASIC_ROW_NUMBER + 1 - gridLength;
  }

  for (let i = 1; i < gridLength; i += 1) {
    let isEmpty = true;
    for (let j = 1; j < grid[gridLength - i].length; j += 1) {
      if (!grid[gridLength - i][j].readOnly && grid[gridLength - i][j].value) {
        isEmpty = false;
        break;
      }
    }
    if (isEmpty) {
      currentEmptyNumber += 1;
    } else {
      break;
    }
  }

  if (currentEmptyNumber + appendNumber < BASIC_EMPTY_NUMBER) {
    appendNumber += BASIC_EMPTY_NUMBER - currentEmptyNumber;
  }
  if (appendNumber > 0) {
    const emptyObj = [...new Array(appendNumber)].map((v, index) => {
      return grid[0].map((vv, iindex) => {
        if (iindex === 0) {
          return { value: `${grid.length + index}`, readOnly: true };
        }
        return { value: '', readOnly: false };
      });
    });
    setGrid([...grid, ...emptyObj]);
  }
};

const XlsxTable = (props) => {
  const { grid, setGrid } = props;
  // grip: Array<Array<{ value: string, readOnly: boolean }>>
  keepEmptyRows(props);
  return (
    <ReactDataSheet
      data={grid}
      valueRenderer={cell => cell.value}
      onCellsChanged={changes => {
        const upgrid = grid.map(row => [...row]);
        changes.forEach(({ cell, row, col, value }) => {
          upgrid[row][col] = { ...upgrid[row][col], value };
        });
        setGrid(upgrid);
      }}
      handleCopy={custHandleCopy}
      parsePaste={custParser}
      valueViewer={(props) => {
        if (!props.value) {
          return <p></p>
        }
        return <p>{props.value.split('\n').map((lineValue) => {
          return <span className={'value-viewer'}>{lineValue}<br/></span>
        })}</p>;
      }}
      dataEditor={props => <TextAreaInput {...props}/>}
    />
  );
};

export default XlsxTable;
