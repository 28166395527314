import React, { forwardRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { default as NumericFormat } from 'react-number-format';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputAdornment from '@material-ui/core/InputAdornment';
import { NavLink as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { connect } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import CircularProgress from '@material-ui/core/CircularProgress';
import EmailDialog from './emailDialog';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Loader from '../../image/Loading.gif';

import { httpRequest, reqObj2From, form2ReqObj, mapsConst } from '../../lib';
import { checkEngine } from '../../common/ruleEngine';
import { setDealList, setLoading } from '../../actions';
import { autoFillEndUser } from './autofillLib';
import envConfig from '../../configuration/env.json';

const ORG_UPDATE_MODE_STR = ['org', 'default'];
const ALL_UPDATE_MODE_STR = ['all', 'every'];

const USER_AUTO_FILL_ATTRIBUTE = ['user_compony_en', 'user_compony_tw', 'user_job_title', 'user_mail', 'user_name', 'user_phone', 'user_tax_id', 'user_work_phone'];
// const USER_AUTO_FILL_ATTRIBUTE = ['user_compony_en', 'user_compony_tw', 'user_department', 'user_job_title', 'user_mail', 'user_name', 'user_phone', 'user_tax_id', 'user_work_phone'];
const SELLER_AUTO_FILL_ATTRIBUTE = ['seller_compony_en', 'seller_compony_tw', 'seller_mail', 'seller_name', 'seller_phone'];
const AGENT_AUTO_FILL_ATTRIBUTE = ['agent_locale', 'agent_name'];

const DEFAULT_LAYOUT = [{
  title_id: 'data_form',
  attr: [
    'data_form'
  ]
}];

const AUTOFILL_TOGGLE = true;
const NEW_CASE_TASK_LOG_TOGGLE = false;
const UPDATE_CASE_TASK_LOG_TOGGLE = true;

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const WithRefreshButtonLayout = (props) => {
  // props.onRefreshClick: () => void
  // props.children
  return (
    <Grid item md={GridInputSize.md} sm={GridInputSize.sm} xs={GridInputSize.xs}>
      <div>
        <div style={{ width: '80%', float: 'left' }}>
          {props.children}
        </div>
        <div style={{ float: 'left' }}>
          <IconButton aria-label="refresh" onClick={props.onRefreshClick}>
            <RefreshIcon />
          </IconButton>
        </div>
      </div>
    </Grid>
  );
};

const formatDateStr = (obj) => {
  if (!obj) {
    return null;
  }
  const datemyDay = (typeof obj.getMonth === 'function') ? obj : new Date(obj);
  // return datemyDay;

  const yyyy = datemyDay.getFullYear();
  const mm = (datemyDay.getMonth() < 9) ? `0${datemyDay.getMonth() + 1}` : datemyDay.getMonth() + 1;
  const dd = (datemyDay.getDate() < 10) ? `0${datemyDay.getDate()}` : datemyDay.getDate();

  return `${yyyy}-${mm}-${dd}`;
};

// TODO: check every input
const checkEditPermission = (sdForm, formData) => {
  // only edit deals from ec-deal-track or org equal ec.
  const cep = sdForm.some((cfg) => {
    if (ALL_UPDATE_MODE_STR.includes(cfg.crm_update_mode)) {
      return cfg.name === formData.dataForm;
    }
    if (ORG_UPDATE_MODE_STR.includes(cfg.crm_update_mode)) {
      return cfg.crm_org.includes(formData.sys_organization);
    }
    return false;
  });
  return !cep;
};

const validateValueInLookup = (value, attr) => {
  if (Array.isArray(value)) {
    return value.every((v) => {
      return attr.lookup.some((option) => {
        return option.value && option.value === v;
      });
    });
  }

  return attr.lookup.some((option) => {
    return option.value && option.value === value;
  });
};

const dataValidation = (isNew, form, formInfo, mapConst) => {
  let alertObj = null;
  // check required fields - dataForm
  if (isNew && !form.dataForm) {
    return {
      status: false,
      message: 'Missing data form',
      messageId: 'missing_data_form'
    };
  }
  // check required fields - status_progress
  // nosemgrep eslint.detect-object-injection -- mapConst is config value
  const statusProgressAttr = formInfo.map[mapConst.status_progress];
  // nosemgrep eslint.detect-object-injection -- mapConst is config value
  const productTypeAttr = formInfo.map[mapConst.product_type];
  // case_status_description 作為產品類別使用
  // nosemgrep eslint.detect-object-injection -- mapConst is config value
  const caseStatusDescriptionAttr = formInfo.map[mapConst.case_status_description];
  // nosemgrep eslint.detect-object-injection -- mapConst is config value
  const transactionPartyTypeAttr = formInfo.map[mapConst.transaction_party_type];

  // Validate Progress
  if (envConfig.validateFormRule.statusProgress.enable && !validateValueInLookup(form.status_progress, statusProgressAttr)) {
    return {
      status: false,
      message: 'Incorrect status progress',
      messageId: 'incorrect_status_progress'
    };
  }
  // Validate Product Type
  if (envConfig.validateFormRule.productType.enable && !validateValueInLookup(form.product_type, productTypeAttr)) {
    return {
      status: false,
      message: 'Incorrect product type',
      messageId: 'incorrect_product_type'
    };
  }
  // Validate Product Category
  if (envConfig.validateFormRule.caseStatusDescription.enable && !validateValueInLookup(form.case_status_description, caseStatusDescriptionAttr)) {
    return {
      status: false,
      message: 'Incorrect product category',
      messageId: 'incorrect_product_category'
    };
  }
  // Validate Transaction Type
  if (envConfig.validateFormRule.transactionType.enable && !validateValueInLookup(form.transaction_party_type, transactionPartyTypeAttr)) {
    return {
      status: false,
      message: 'Incorrect Transaction Type',
      messageId: 'incorrect_transaction_type'
    };
  }

  // add other validation here
  return { status: true, message: 'Success', messageId: 'success' };
};

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ float: 'left', marginRight:'10px' }}>
    <RouterLink {...props} />
  </div>
));

const GridInputSize = { md: 4, sm: 6, xs: 12 };

const autoTextInput = (label, value, setVariable, options, optionKey, disabled = false) => {
  const optionArray = options.map((option) => {
    return option[optionKey];
  }).filter((optionValue) => {
    return optionValue;
  });
  return (
    <Autocomplete
      freeSolo
      disabled={disabled}
      value={value}
      options={[...new Set(optionArray)]}
      onInputChange={(event, value) => {
        if (event) {
          setVariable(value);
        }
      }}
      renderInput={(params) => {
        return (
          <TextField {...params} label={label} variant={disabled ? 'filled' : 'outlined'} fullWidth/>
        );
      }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: '50px'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const _dateInput = (label, value, setVariable, disabled = false) => {
  return (
    <Grid item md={GridInputSize.md} sm={GridInputSize.sm} xs={GridInputSize.xs} >
      <label>{label}</label>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          fullWidth
          inputVariant="outlined"
          id="date-picker-dialog"
          value={value}
          format="yyyy-MM-dd"
          onChange={(cValue) => { setVariable(cValue); }}
          KeyboardButtonProps={{ 'aria-label': 'change date' }}
          disabled={disabled}
        />
        </MuiPickersUtilsProvider>
    </Grid>
  );
};
const _textInput = (type, label, value, setVariable, multiline = false, disabled = false) => {
  return (
    <Grid item md={GridInputSize.md} sm={GridInputSize.sm} xs={GridInputSize.xs} >
      <label>{label}</label>
      <TextField
        fullWidth
        type={type}
        value={value}
        disabled={disabled}
        multiline={multiline}
        variant={disabled?'filled':'outlined'}
        onChange={(event) => {
          setVariable(event.target.value);
        }}
      />
    </Grid>
  );
};

const _checkboxInput = (label, value, setVariable, disabled = false) => {
  return (
    <Grid item md={GridInputSize.md} sm={GridInputSize.sm} xs={GridInputSize.xs} >
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={(event) => {
              setVariable(event.target.checked);
            }}
            name="checkedB"
            color="primary"
          />
        }
        label={label}
      />
    </Grid>
  );
};

const getCompanyNameByTax = (accessToken, tax, callback) => {
  httpRequest('GET', `/api/tax/tw/company/${tax}`, {}, accessToken, (statusCode, body) => {
    callback(body.status, body.companyName);
  });
};

const _taxTextInput = (label, value, setVariable, onRefreshClick, disabled = false) => {
  return (
    <Grid item md={GridInputSize.md} sm={GridInputSize.sm} xs={GridInputSize.xs} >
      <label>{label}</label>
      <div>
        <div style={{ width: '80%', float: 'left' }}>
          <TextField
            fullWidth
            value={value}
            disabled={disabled}
            variant={disabled?'filled':'outlined'}
            onChange={(event) => {
              setVariable(event.target.value);
            }}
          />
        </div>
        <div style={{ float: 'left' }}>
          <IconButton
            aria-label="refresh"
            onClick={onRefreshClick}
          >
            <RefreshIcon />
          </IconButton>
        </div>
      </div>
    </Grid>
  );
};

const _amountInput = (label, value, setVariable, disabled = false) => {
  return (
    <Grid item md={GridInputSize.md} sm={GridInputSize.sm} xs={GridInputSize.xs} >
      <label>{label}</label>
      <TextField
        fullWidth
        type={'text'}
        value={value}
        disabled={disabled}
        variant={disabled ? 'filled' : 'outlined'}
        onChange={(event) => {
          setVariable(event.target.value);
        }}
        InputProps={{
          startAdornment: <InputAdornment position='start'>$</InputAdornment>,
          inputComponent: NumberFormatCustom
        }}
      />
    </Grid>
  );
};

const renderField = (props, fieldInfo, value, setVariable, disabled = false) => {
  const displayName = props.intl.formatMessage({ id: fieldInfo.title_id });
  switch(fieldInfo.fieldType) {
    case 'select': {
      return (
        <Grid item md={GridInputSize.md} sm={GridInputSize.sm} xs={GridInputSize.xs} >
          <FormControl disabled={disabled} variant={disabled?'filled':'outlined'} fullWidth>
            <label>{displayName}</label>

            <Select
              style={{ width: '100%'}}
              // labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={value}
              displayEmpty={true}
              onChange={(event) => {
                setVariable(event.target.value);
              }}
              label={fieldInfo.title.split('\n')[0]}
            >
              {
                fieldInfo.lookup.map((item) => {
                  return <MenuItem value={item.value}>{item.label}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </Grid>
      );
    }
    case 'mutiselect': {
      return (
        <Grid item md={GridInputSize.md} sm={GridInputSize.sm} xs={GridInputSize.xs} >
          <FormControl disabled={disabled} variant={disabled?'filled':'outlined'} fullWidth>
            <label>{displayName}</label>

            <Select
              style={{ width: '100%'}}
              // labelId="demo-simple-select-outlined-label"
              id="demo-simple-mutiselect-outlined"
              multiple
              value={value || []}
              displayEmpty={true}
              onChange={(event) => {
                const mValue = typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value;
                const value = mValue.map((v) => {
                  return v.trim();
                }).filter((v) => {
                  return v;
                });
                setVariable(value);
              }}
              label={fieldInfo.title.split('\n')[0]}
            >
              {
                fieldInfo.lookup.map((item) => {
                  return <MenuItem value={item.value}>{item.label}</MenuItem>
                })
              }
            </Select>
          </FormControl>
        </Grid>
      );
    }
    case 'datetime': {
      // return _textInput('datetime-local', displayName, value, setVariable, false, disabled);
      return _textInput('datetime-local', displayName, new Date(value), setVariable, false, disabled);
    }
    case 'date': {
      return _dateInput(displayName, formatDateStr(value), setVariable, disabled);
    }
    case 'amount': {
      return _amountInput(displayName, value, setVariable, disabled);
    }
    case 'multiline': {
      return _textInput('text', displayName, value, setVariable, true, disabled);
    }
    case 'checkbox': {
      return _checkboxInput(displayName, value, setVariable, disabled);
    }
    case 'text':
    default: {
      return _textInput('text', displayName, value, setVariable, false, disabled);
    }
  }
}

// *******************************************************************************************
// Main function
// *******************************************************************************************
const DealListDetail = (props) => {
  let history = useHistory();
  const classes = useStyles();

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [deal_index, set_deal_index] = React.useState(-1);
  const [isNew, setIsNew] = React.useState(false);
  const [ownerUid, setOwnerUid] = React.useState('');
  const [isOwnerEdited, setIsOwnerEdited] = React.useState(false);
  const [isFormEdited, setIsFormEdited] = React.useState(false);
  const [openEmailDialog, setOpenEmailDialog] = React.useState(false);
  const [sheetDataFormList, setSheetDataFormList] = React.useState([]);
  const [formInfoIndex, setFormInfoIndex] = React.useState(-1);
  const [formValue, setFormValue] = React.useState('');
  const [autoFillUserDeals, setAutoFillUserDeals]  = React.useState([]);
  const [autoFillSellerDeals, setAutoFillSellerDeals]  = React.useState([]);
  const [autoFillAgentDeals, setAutoFillAgentDeals]  = React.useState([]);
  // const [formValue, setFormValue] = React.useState(''props.formInfos[formInfoIndex].layout.initUseAttr);

  useEffect(() => {
    window.scrollTo(0, 0);
    const { pathname } = window.location;
    const sys_case_uid = pathname.split('/').slice(-1)[0];
    if (sys_case_uid === 'new') {
      // add new
      // const newForm = reqObj2From(props.formInfos[formInfoIndex], {});
      // newForm.dataForm = props.formInfos[formInfoIndex].name;
      // newForm.case_status = 'Waiting';
      const newForm = {
        dataForm: '',
        case_status: 'Waiting'
      };
      setIsNew(true);
      setIsOwnerEdited(false);
      setIsFormEdited(false);
      setFormValue({ ...newForm });
      setSheetDataFormList(props.sheetDataForm.map((item) => {
        return { label: item.name, value: item.name };
      }));
      return;
    }

    // load form value from reducer.
    const index = props.dealList.findIndex((deal) => {
      return deal.sys_case_uid === sys_case_uid;
    });
    if (index === -1) {
      // redirect to back page
      window.location.href = "/pipeline";
      return;
    }
    const fiIndex = props.formInfos.findIndex((fi) => {
      return fi.name === props.dealList[index].dataForm;
    });
    set_deal_index(index);
    setIsOwnerEdited(false);
    setIsFormEdited(false);
    setFormValue({ ...props.dealList[index] });
    setOwnerUid(props.dealList[index].owner_uid);
    setSheetDataFormList(props.sheetDataForm.map((item) => {
      return { label: item.name, value: item.name };
    }));
    setFormInfoIndex(fiIndex);
  }, []); // let it run one time when mount.

  const formInput = (attrKey) => {
    const mapConst = mapsConst(props.formInfos[formInfoIndex]);
    // nosemgrep eslint.detect-object-injection -- mapConst is config value
    const attr = props.formInfos[formInfoIndex].map[mapConst[attrKey]];
    const displayName = props.intl.formatMessage({ id: attr.title_id });
    const key = `${attr.type}_${attr.key}`;
    const status = formValue.case_status;
    const disabled = !(attr.allowEditStage.includes(status) || attr.adminRole.includes(props.account.role));

    // init form value in here.
    let displayValue = formValue[key] || '';
    if (key === 'agent_DR_Number' && !['Approved', 'Expired', 'Archive'].includes(status)) {
      displayValue = 'N/A'
    }
    // check fieldInfo: { type: user, key: tax_id }
    // AUTOFILL
    if (attr.type === 'user' && attr.key === 'tax_id') {
      return (
        <WithRefreshButtonLayout
          title={displayName}
          onRefreshClick={() => {
            getCompanyNameByTax(props.auth.accessToken, displayValue, (status, CompanyName) => {
              if (status) {
                const json = { ...formValue };
                json.user_compony_tw = CompanyName;
                setIsFormEdited(true);
                setFormValue(json);
              }
            });
          }}
        >
          {
            AUTOFILL_TOGGLE ?
            autoTextInput(displayName, formValue[key], (eValue) => {
              const json = { ...formValue, [key]: eValue };
              const afud = autoFillEndUser(props.dealList, json, USER_AUTO_FILL_ATTRIBUTE);
              setIsFormEdited(true);
              setFormValue(json);
              setAutoFillUserDeals(afud);
            }, autoFillUserDeals, key, disabled) :
            <TextField
              fullWidth
              value={formValue[key]}
              disabled={disabled}
              variant={disabled ? 'filled' : 'outlined'}
              onChange={(event) => {
                const json = { ...formValue, [key]: event.target.value };
                setIsFormEdited(true);
                setFormValue(json);
              }}
            />
          }
        </WithRefreshButtonLayout>
      );
    }
    // auto fill field
    if (AUTOFILL_TOGGLE && attr.fieldType === 'text' && ['user', 'seller'].includes(attr.type)) {
      const isUserType = attr.type === 'user';
      const fillAttrs = isUserType ? USER_AUTO_FILL_ATTRIBUTE : SELLER_AUTO_FILL_ATTRIBUTE;
      const autoFillDeals = isUserType ? autoFillUserDeals : autoFillSellerDeals;
      const setAutoFillDeals = isUserType ? setAutoFillUserDeals : setAutoFillSellerDeals;
      return (
        <Grid item md={GridInputSize.md} sm={GridInputSize.sm} xs={GridInputSize.xs} >
          {
            autoTextInput(displayName, formValue[key], (eValue) => {
              const json = { ...formValue, [key]: eValue };
              const afud = autoFillEndUser(props.dealList, json, fillAttrs);
              setIsFormEdited(true);
              setFormValue(json);
              setAutoFillDeals(afud);
            }, autoFillDeals, key, disabled)
          }
        </Grid>
      );
    }
    if (
      AUTOFILL_TOGGLE &&
      attr.fieldType === 'text' &&
      attr.type === 'agent' &&
      ['locale', 'name'].includes(attr.key)
    ) {
      return (
        <Grid item md={GridInputSize.md} sm={GridInputSize.sm} xs={GridInputSize.xs} >
          {
            autoTextInput(displayName, formValue[key], (eValue) => {
              const json = { ...formValue, [key]: eValue };
              const afud = autoFillEndUser(props.dealList, json, AGENT_AUTO_FILL_ATTRIBUTE);
              setIsFormEdited(true);
              setFormValue(json);
              setAutoFillAgentDeals(afud);
            }, autoFillAgentDeals, key, disabled)
          }
        </Grid>
      );
    }


    return renderField(props, attr, displayValue, (value) => {
      const json = { ...formValue };
      json[key] = value;
      setIsFormEdited(true);
      setFormValue(json);
    }, disabled); // globalEditLock || disabled
  };

  const onCloseEmailDialog = (event) => {
    setOpenEmailDialog(false);
  };

  const onSubmitEmail = (form) => {
    /*
    form: {
      receiver: Array<string>,
      ccReceiver: Array<string>,
      bccReceiver: Array<string>,
      emailSubject: string,
      emailContent: string
    }
    */
    props.setLoading(true);
    httpRequest('POST', '/api/email', form, props.auth.accessToken, (statusCode, body) => {
      // TODO: add response here
      setOpenEmailDialog(false);
      props.setLoading(false);
      confirmAlert({
        title: body.status ? 'Success' : 'Warning',
        message: body.status ? 'Success' : body.message,
        buttons: [{ label: 'Yes' }],
        closeOnClickOutside: false
      });
    });
  };

  const generagePotentialName = (form) => {
    const removeStr = ['股份有限公司', '有限公司', '公司'];
    const fieldKeys = [
      'user_compony_tw',
      'seller_compony_tw',
      'case_status_description',
      'agent_DR_Number'
    ];
    return fieldKeys.map((key) => {
      if (typeof key === 'string') {
        const str = removeStr.reduce((preResult, fieldStr) => {
          if (Array.isArray(preResult)) {
            return preResult.join('-').replace(fieldStr, '');
          }
          return preResult.replace(fieldStr, '');
        }, form[key]);
  
        return str.slice(0, 15);
      }
      return null;
    }).filter((value) => {
      return value;
    }).join('-');
  };

  const accPreSync = () => {
    const method = 'POST';
    const endpoint = '/api/proxy/ectec';
    const reqBody = {
      dataForm: formValue.dataForm,
      name: formValue.owner_name,
      data: {
        potential_name: generagePotentialName(formValue)
      }
    };

    httpRequest(method, endpoint, reqBody, props.auth.accessToken, (statusCode, body) => {
      confirmAlert({
        title: body.status ? 'Success' : 'Warning',
        message: body.status ? 'Success' : body.message,
        buttons: [{ label: 'Yes' }],
        closeOnClickOutside: false
      });
    });
  };

  const advancedPage = () => {
    const ALLOW_ROLE = ['admin', 'manager'];
    if (!ALLOW_ROLE.includes(props.account.role) || isNew) {
      return null;
    }

    const {
      owner_email,
      owner_name,
      owner_uid
    } = formValue;
    const ownerOptions = [{ label: 'N/A', value: '' }];
    props.orgMember.filter((om) => {
      return om.dataForm === formValue.dataForm;
    }).forEach((om) => {
      ownerOptions.push({ label: `${om.name}(${om.email})`, value: om.user_uid });
    });

    const ownerFieldInfo = {
      title_id: 'owner',
      fieldType: 'select',
      title: 'Owner',
      lookup: ownerOptions
    };

    const setCurrentUser = (targetValue) => {
      setIsOwnerEdited(true);
      setOwnerUid(targetValue);
    };
    return (
      <Paper className={classes.paper}>
        <Typography variant='h3'>
          <FormattedMessage id='advance_title' />
        </Typography>
        <Grid container spacing={3}>
          {renderField(props, ownerFieldInfo, ownerUid, setCurrentUser, false)}
          <Grid item md={GridInputSize.md} sm={GridInputSize.sm} xs={GridInputSize.xs} >
            <Button
              style={{ width: '100%', marginTop: '22px', height: '40px' }}
              variant="contained"
              onClick={() => setOpenEmailDialog(true)}
            >
              <FormattedMessage id='send_email' />
            </Button>
          </Grid>
          <Grid item md={GridInputSize.md} sm={GridInputSize.sm} xs={GridInputSize.xs} >
            <Button
              style={{ width: '100%', marginTop: '22px', height: '40px' }}
              variant="contained"
              onClick={accPreSync}
            >
              <FormattedMessage id='acc_presync' />
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const warnInfomation = () => {
    const warnRules = props.notifyRule.filter((myRule) => {
      return checkEngine(myRule, formValue);
    }).map((myRule) => {
      return <li style={{ color: '#FF0000' }}><FormattedMessage id={myRule.notifyMsgID} /></li>
    });

    return (warnRules.length === 0) ? null : (
      <Paper className={classes.paper}>
        <Typography variant='h3'>
          <FormattedMessage id='warning_title' />
        </Typography>
        <ul>{warnRules}</ul>
      </Paper>
    );
  };

  const onSubmitForm = async (event) => {
    const formInfoIndex = props.formInfos.findIndex((formInfo) => {
      return formInfo.name === formValue.dataForm;
    });
    const mapConst = mapsConst(props.formInfos[formInfoIndex]);
    const vaildateInfo = dataValidation(isNew, formValue, props.formInfos[formInfoIndex], mapConst);

    if (!vaildateInfo.status) {
      confirmAlert({
        title: 'Warning',
        message: props.intl.formatMessage({ id: vaildateInfo.messageId }),
        closeOnClickOutside: false,
        buttons: [{ label: 'Confirm' }]
      });
      return;
    }

    setOpenBackdrop(true);
    const method = isNew ? 'PUT' : 'POST';
    const endpoint = isNew ? `/api/dealtrack/rows/${formValue.dataForm}` : `/api/dealtrack/${formValue.dataForm}/${formValue.sys_case_uid}`;

    const reqBody = isNew ? { rows: form2ReqObj(props.formInfos[formInfoIndex], [formValue]) } : { data: form2ReqObj(props.formInfos[formInfoIndex], [formValue])[0] };
    let dialogMsgBody = { status: true, message: 'Success' };

    if (dialogMsgBody.status && isFormEdited) {
      dialogMsgBody = await new Promise((resolve, reject) => {
        httpRequest(method, endpoint, reqBody, props.auth.accessToken, (statusCode, body) => {
          setIsFormEdited(false);
          resolve(body);
        });
      });
    }

    if (dialogMsgBody.status && isOwnerEdited) {
      dialogMsgBody = await new Promise((resolve, reject) => {
        const { sys_case_uid: caseUid } = formValue;
        httpRequest('post', '/api/manager/chowner', {
          caseUid,
          userUid: ownerUid,
          dataForm: props.formInfos[formInfoIndex].name
        }, props.auth.accessToken, (statusCode, body) => {
          resolve(body);
        });
      });
    }

    if (!dialogMsgBody.status) {
      // show error and reload
      confirmAlert({
        title: 'Warning',
        message: dialogMsgBody.message,
        buttons: [{ label: 'Yes', onClick: () => history.push('/pipeline') }],
        closeOnClickOutside: false
      });
      setOpenBackdrop(false);
      return;
    }
    if (UPDATE_CASE_TASK_LOG_TOGGLE && !isNew || NEW_CASE_TASK_LOG_TOGGLE && isNew) {
      // 將當前更動資料寫入redux
      // 新增的缺少case id所以無法更新至redux
      if (!isNew) {
        const { dealList } = props;
        if (isFormEdited) {
          dealList[deal_index] = formValue;
          dealList[deal_index].sys_last_update_stamp = new Date();
        }
        if (isOwnerEdited) {
          setIsOwnerEdited(false);
          const newOwnerInfo = props.orgMember.find((om) => om.user_uid === ownerUid);
          dealList[deal_index].owner_email = newOwnerInfo.email;
          dealList[deal_index].owner_name = newOwnerInfo.name;
          dealList[deal_index].owner_uid = ownerUid;

          dealList[deal_index].sys_organization = newOwnerInfo.role;
        }
        props.setDealList(dealList);
      }
      setOpenBackdrop(false);
      confirmAlert({
        title: 'Success',
        message: 'Success',
        buttons: [{ label: 'Yes', onClick: () => history.push('/pipeline')  }],
        closeOnClickOutside: false
      });
    } else {
      setIsOwnerEdited(false);
      httpRequest('GET', '/api/dealtrack', {}, props.auth.accessToken, (statusCode, response) => {
        const { status, message, data } = response;
        if (!status) { return; }
        const formatData = data.map((row) => {
          const ownerObj = {
            owner_name: row.owner.name,
            owner_email: row.owner.email,
            owner_uid: row.owner.user_uid
          };
          const formInfoIndex = props.formInfos.findIndex((formInfo) => {
            return formInfo.name === row.dataForm; // TODO: check key row.dataForm
          });
          return { ...reqObj2From(props.formInfos[formInfoIndex], row), ...ownerObj };
        });
        confirmAlert({
          title: 'Success',
          message: 'Success',
          buttons: [{ label: 'Yes', onClick: () => history.push('/pipeline')  }],
          closeOnClickOutside: false
        });
  
        setOpenBackdrop(false);
        props.setDealList(formatData);
      });
    }
  };

  const layoutArray = formInfoIndex > -1 ? props.formInfos[formInfoIndex].layout.classes : DEFAULT_LAYOUT;
  return (deal_index === -1 && !isNew) ? null : (
    <>
    <div className={classes.root}>
      {warnInfomation()}
      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Paper className={classes.paper}>
        {
          layoutArray.map((map) => {
            return (
              <div>
                <div style={{ padding: '10px 0px'}}>
                  <div style={{ padding: '10px 0px'}}>
                    <Typography variant='h3'>
                      <FormattedMessage id={map.title_id} />
                    </Typography>
                  </div>
                  <Grid container spacing={3}>
                    {map.attr.map((attrName) => {
                      if (attrName === 'data_form') {
                        // =========== data_form select form in here ===========
                        const dataFormInfo = {
                          title_id: 'data_form',
                          fieldType: 'select',
                          title: 'data_form',
                          lookup: sheetDataFormList
                        };
                        return renderField(props, dataFormInfo, formValue.dataForm, (value) => {
                          const json = { ...formValue };
                          const fIndex = sheetDataFormList.findIndex((form) => {
                            return form.value === value;
                          });
                          json.dataForm = value;
                          // change form layout
                          setFormValue(json);
                          setFormInfoIndex(fIndex);
                        }, !isNew); 
                        return;
                      }
                      return formInput(attrName);
                    })}
                  </Grid>
                </div>
                <Divider />
              </div>
            );
          })
        }
      </Paper>
      {advancedPage()}
      <div style={{ padding: '10px 0px', position: 'fixed', bottom: '10px', right: '30px' }}>
        <Button style={{ width: '90px' }} variant="contained" component={CustomRouterLink} to={'/pipeline'} >
        <FormattedMessage id='back'/>
        </Button>
        {
          ['Archive', 'Expired'].includes(formValue.case_status) && props.account.role !== 'admin' ?
          null : <Button style={{ width: '90px' }} variant="contained" color="primary" onClick={onSubmitForm}>
            <FormattedMessage id={isNew ? 'add' : 'update'}/>
          </Button>
        }
      </div>
      <EmailDialog
        open={openEmailDialog}
        onClose={onCloseEmailDialog}
        orgMember={props.orgMember}
        ownerEmail={formValue.owner_email}
        onSubmitEmail={onSubmitEmail}
        form={formValue}
      />
    </div>
    { props.loading && (
      <div style={{zIndex:'10',position:'fixed', top:'0', left: '0', backgroundColor:'#D3D3D3', opacity:'0.85', height:'100vh', width:'100vw', display:'flex', justifyContent:'center', alignItems:'center'}}>
        <div style={{  textAlign:'center'}}>
          <img
                src={Loader}
                style={{ width: '200px' }}
                alt='Loading...'
              />
          <h3 style={{color:'white'}}>Loading...</h3>
        </div>
      </div>
    )}
    </>
  );
}

// export default DealList;

const mapStateToProps = state => {
  const { auth, dealList, loading, account, notifyRule, orgMember, sheetDataForm, formInfos } = state;
  return { auth, dealList, loading, account, notifyRule, orgMember, sheetDataForm, formInfos };
};

export default connect(mapStateToProps, { setDealList, setLoading })(withAuthenticationRequired(injectIntl(DealListDetail)));
