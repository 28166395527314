import { CONSTANTS } from "./constants";

const ORDER = 'asc';

export default (state = ORDER, action) => {
  switch (action.type) {
    case CONSTANTS.SET_ORDER :
      return action.value;

    default:
      return state;
  }
}
