import { CONSTANTS } from "./constants";

const NOTIFY_RULES = [];

export default (state = NOTIFY_RULES, action) => {
  switch (action.type) {
    case CONSTANTS.SET_NOTIFY_RULE :
      return JSON.parse(JSON.stringify(action.value));

    default:
      return state;
  }
}
