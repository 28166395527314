import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart } from 'react-chartjs-2';
import { ThemeProvider } from '@material-ui/styles';
import { Auth0Provider } from "@auth0/auth0-react";
// import { Provider } from 'react-redux'
// import { createStore } from 'redux'

import validate from 'validate.js';

import { chartjs } from './helpers';
import theme from './theme';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';
import validators from './common/validators';
import Routes from './Routes';
import envConfig from './configuration/env.json';

// import rootReducer from './reducer';
// const store = createStore(rootReducer);

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw
});

validate.validators = {
  ...validate.validators,
  ...validators
};

const onRedirectCallback = (appState) => {
  // Use the router's history module to replace the url
  browserHistory.replace(appState?.returnTo || window.location.pathname);
};

export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Auth0Provider
          domain={envConfig.domain}
          clientId={envConfig.clientId}
          redirectUri={`${window.location.origin}/sign-in`}
          audience={envConfig.audience}
          scope={envConfig.scope}
          onRedirectCallback={onRedirectCallback}
        >
        
          <Router history={browserHistory}>
            <Routes />
          </Router>
        
        </Auth0Provider>
      </ThemeProvider>
    );
  }
}
