import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedDate, injectIntl } from 'react-intl';
import { NavLink as RouterLink } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EmailIcon from '@material-ui/icons/Email';

const EDIT_STR_SET = ['edit', 'view'];
const EMAIL_STR_SET = ['email'];
const DATE_STR_SET = ['date'];
const SELECT_STR_SET = ['select'];
const CHECKBOX_STR_SET = ['checkbox'];
const NUMBER_STR_SET = ['number'];
const TEXT_STR_SET = ['text', 'multiline'];
const MULTILINE_STR_SET = ['multiline'];

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));


const TableBodyCellViewer = (props) => {
  if (EDIT_STR_SET.includes(props.headCellInfo.type)) {
    const tooltipTitle = props.intl.formatMessage({
      id: props.headCellInfo.label_id,
      defaultMessage: props.headCellInfo.id
    });
    return (
      <Tooltip title={tooltipTitle}>
        <IconButton aria-label="view" component={CustomRouterLink} to={props.value}>
          {
            props.editing ? (
              <EditIcon style={{ color:'#ffb549' }}/>
            ) : (
              <VisibilityIcon style={{ color:'#dadada' }}/>
            )
          }
        </IconButton>
      </Tooltip>
    );
  }
  
  if (EMAIL_STR_SET.includes(props.headCellInfo.type)) {
    const tooltipTitle = props.intl.formatMessage({
      id: props.headCellInfo.label_id,
      defaultMessage: props.headCellInfo.id
    });
    return (
      <Tooltip title={tooltipTitle}>
        <IconButton aria-label="email" onClick={props.onCellClick}>
          <EmailIcon/>
        </IconButton>
      </Tooltip>
    );
  }
  if (DATE_STR_SET.includes(props.headCellInfo.type)) {
    if (props.editing) {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            fullWidth
            inputVariant="outlined"
            id="date-picker-dialog"
            value={props.value || ''}
            format="yyyy-MM-dd"
            onChange={(cValue) => props.onCellChange(cValue)}
            KeyboardButtonProps={{ 'aria-label': 'change date' }}
          />
        </MuiPickersUtilsProvider>
      );
    }
    if (props.value) {
      return (
        <FormattedDate value={props.value} />
      );
    }
    return props.value;
  }
  if (SELECT_STR_SET.includes(props.headCellInfo.type)) {
    if (props.editing) {
      return (
        <Select
          style={{ width: '100%'}}
          value={props.value || ''}
          displayEmpty={true}
          onChange={(event) => {
            props.onCellChange(event.target.value);
          }}
        >
          {
            props.cellAttInfo.lookup.map((item) => {
              const displayLabelParts = item.label.split(' (');
              if (displayLabelParts.length === 2) {
                return (
                  <MenuItem value={item.value}>
                    {displayLabelParts[0]}<br/>{`(${displayLabelParts[1]}`}
                  </MenuItem>
                );
              }
              return (
                <MenuItem value={item.value}>
                  {item.label}
                </MenuItem>
              );
            })
          }
        </Select>
      );
    }
    // TODO: render select by label_id
    return props.value || 'N/A';
  }
  if (CHECKBOX_STR_SET.includes(props.headCellInfo.type)) {
    return (
      <Checkbox
        checked={props.value || false}
        onChange={(event) => {
          props.onCellChange(event.target.checked);
        }}
        name="checked"
        color="primary"
        disabled={!props.editing}
      />
    );
  }
  if (NUMBER_STR_SET.includes(props.headCellInfo.type)) {
    if (props.editing) {
      return (
        <TextField
          fullWidth
          value={props.value || ''}
          variant='outlined'
          type='number'
          onChange={(event) => {
            props.onCellChange(event.target.value);
          }}
        />
      );
    }
    return props.value;
  }
  // default text edit
  if (TEXT_STR_SET.includes(props.headCellInfo.type) && props.editing) {
    return (
      <TextField
        fullWidth
        value={props.value || ''}
        variant='outlined'
        multiline={MULTILINE_STR_SET.includes(props.headCellInfo.type)}
        onChange={(event) => {
          props.onCellChange(event.target.value);
        }}
      />
    );
  }
  // default only return value
  // console.log('default', props.value);
  return props.value;
};

TableBodyCellViewer.propTypes = {
  cellAttInfo: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    title_id: PropTypes.string,
    displayName: PropTypes.string,
    key: PropTypes.string,
    description: PropTypes.string,
    bgColor: PropTypes.object,
    allowEditStage: PropTypes.arrayOf(PropTypes.string),
    display: PropTypes.bool,
    adminRole: PropTypes.arrayOf(PropTypes.string),
    fieldType: PropTypes.string.isRequired,
    lookup: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string
      })
    ).isRequired,
  }).isRequired,
  headCellInfo: PropTypes.shape({
    sortable: PropTypes.bool,
    editable: PropTypes.bool,
    id: PropTypes.string,
    label_id: PropTypes.string,
    minWidth: PropTypes.string,
    type: PropTypes.string.isRequired
    // role: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  editing: PropTypes.bool.isRequired,
  value: PropTypes.any.isRequired,
  onCellChange: PropTypes.func.isRequired,
  onCellClick: PropTypes.func.isRequired,
};

export default injectIntl(TableBodyCellViewer);
