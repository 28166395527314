
const CHECK_STATUS_CODES = {
  UNKNOWN_CODE: { value: -1, description: 'Unknown the test' },
  SUCCESS_CODE: { value: 0, description: 'Passed the test' },
  FAIL_CODE: { value: 1, description: 'Failed the test' },
  EMPTY_CODE: { value: 2, description: 'Empty attribute value' },
  SKIP_CODE: { value: 3, description: 'Skip event' }
};

const dateOffset = (dateValue, hh = 0, mm = 0, ss = 0, ms = 0) => {
  const date = new Date(dateValue);
  date.setHours(hh);
  date.setMinutes(mm);
  date.setSeconds(ss);
  date.setMilliseconds(ms);
  return date;
};

const isValidDate = (d: any): boolean => {
  // eslint-disable-next-line no-restricted-globals
  return d instanceof Date && !isNaN(d);
};

const diffDays = (dateStr1, dateStr2) => {
  const date2 = dateOffset(dateStr2);
  const date1 = dateOffset(dateStr1);
  const timeDifference = date1.getTime() - date2.getTime();
  const differentDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
  return differentDays;
};

const checkTextContain = (textValue, options) => {
  return options.some(str => {
    return textValue.includes(str.toString());
  });
};

const textCheck = (attRule, row) => {
  // nosemgrep eslint.detect-object-injection -- attRule is config value
  let theValue = row[`${attRule.attribute.type}_${attRule.attribute.key}`];

  if (!theValue) {
    return CHECK_STATUS_CODES.EMPTY_CODE;
  }

  if (attRule.rule === 'contain') {
    return checkTextContain(theValue, attRule.values) ? CHECK_STATUS_CODES.SUCCESS_CODE : CHECK_STATUS_CODES.FAIL_CODE;
  }

  return CHECK_STATUS_CODES.UNKNOWN_CODE;
};

const dateCheck = (attRule, row) => {
  // nosemgrep eslint.detect-object-injection -- attRule is config value
  let theValue = row[`${attRule.attribute.type}_${attRule.attribute.key}`];
  if (!theValue) {
    return CHECK_STATUS_CODES.EMPTY_CODE;
  }
  const theDate = new Date(theValue);
  const nowDate = new Date();
  const offset = 0;

  if (attRule.rule === 'type') {
    return isValidDate(theDate)
      ? CHECK_STATUS_CODES.SUCCESS_CODE : CHECK_STATUS_CODES.FAIL_CODE;
  }

  if (attRule.rule === 'range') {
    if (typeof attRule.values[0] !== 'number' || typeof attRule.values[1] !== 'number') {
      return CHECK_STATUS_CODES.UNKNOWN_CODE;
    }

    const day = diffDays(nowDate, theDate); // $FlowFixMe

    return attRule.values[0] <= day && day < attRule.values[1] + offset ? CHECK_STATUS_CODES.SUCCESS_CODE : CHECK_STATUS_CODES.FAIL_CODE;
  }

  if (attRule.rule === 'month') {
    if (typeof attRule.values[0] !== 'number' || typeof attRule.values[1] !== 'number') {
      return CHECK_STATUS_CODES.UNKNOWN_CODE;
    }

    return (// $FlowFixMe
      attRule.values[1] <= nowDate.getDate() && nowDate.getDate() < attRule.values[1] + 1 + offset // $FlowFixMe
      && theDate.getMonth() - nowDate.getMonth() === attRule.values[0] ? CHECK_STATUS_CODES.SUCCESS_CODE : CHECK_STATUS_CODES.FAIL_CODE
    );
  }

  if (attRule.rule === 'more_than') {
    if (typeof attRule.values[0] !== 'string' || typeof attRule.values[1] !== 'string' || typeof attRule.values[2] !== 'number') {
      return CHECK_STATUS_CODES.UNKNOWN_CODE;
    }

    // nosemgrep eslint.detect-object-injection -- attRule is config value
    const targetDate = row[`${attRule.values[0]}_${attRule.values[1]}`];
    const day = diffDays(theDate, targetDate); // $FlowFixMe

    return attRule.values[2] <= day ? CHECK_STATUS_CODES.SUCCESS_CODE : CHECK_STATUS_CODES.FAIL_CODE;
  }

  return CHECK_STATUS_CODES.UNKNOWN_CODE;
};

const checkEngine = (rule, row) => {
  const checked = rule.rules.every((attRule, i) => {
    let checkCode = -1;
    if (attRule.rule ==='monthly') {
      return true;
    } else if (attRule.type === 'text') {
      const checkResult = textCheck(attRule, row);
      checkCode = checkResult.value;
    } else if (attRule.type === 'date') {
      const checkResult = dateCheck(attRule, row);
      checkCode = checkResult.value;
    } else {
      return false;
    }
    const isAllowed = attRule.allowCode.includes(checkCode);
    return isAllowed;
  });
  return checked;
};

export {
  checkEngine
};