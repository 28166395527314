import { CONSTANTS } from "../reducer/constants";

let nextTodoId = 0;
export const setAccountInfo = info => ({
  type: CONSTANTS.SET_ACCOUNT_INFO,
  value: info
});

export const setAuthInfo = info => ({
  type: CONSTANTS.SET_AUTH_INFO,
  value: info
});

export const setDealList = arrayInfo => ({
  type: CONSTANTS.SET_DEAL_LIST,
  value: JSON.parse(JSON.stringify(arrayInfo))
});

export const setLoading = (toggle) => ({
  type: CONSTANTS.SET_LOADING,
  value: toggle
});


export const setNotifyRule = (rules) => ({
  type: CONSTANTS.SET_NOTIFY_RULE,
  value: rules
});

export const setOrgMember = (members) => ({
  type: CONSTANTS.SET_ORG_MEMBER,
  value: members
});

// export const setFilterStr = (filterStr) => ({
//   type: CONSTANTS.SET_FILTER_STR,
//   value: filterStr
// });

// export const setStatusFilter = (statusFilter) => ({
//   type: CONSTANTS.SET_STATUS_FILTER,
//   value: statusFilter
// });

// export const setUpdateRangeFilter = (updateRangeFilter) => ({
//   type: CONSTANTS.SET_UPDATE_RANGE_FILTER,
//   value: updateRangeFilter
// });

// export const setProductTypeFilter = (productTypeFilter) => ({
//   type: CONSTANTS.SET_PRODUCT_TYPE_FILTER,
//   value: productTypeFilter
// });

// export const setProgressFilter = (progressFilter) => ({
//   type: CONSTANTS.SET_PROGRESS_FILTER,
//   value: progressFilter
// });

// export const setEstimatedClosingFilter = (estimatedClosingFilter) => ({
//   type: CONSTANTS.SET_ESTIMATED_CLOSING_FILTER,
//   value: estimatedClosingFilter
// });

export const setFilterInfo = (filterInfo) => ({
  type: CONSTANTS.SET_FILTER_INFO,
  value: filterInfo
});

export const setOrder = (order) => ({
  type: CONSTANTS.SET_ORDER,
  value: order
});

export const setOrderBy = (orderBy) => ({
  type: CONSTANTS.SET_ORDER_BY,
  value: orderBy
});

// export const setFilterOwner = (ownerName) => ({
//   type: CONSTANTS.SET_FILTER_OWNER,
//   value: ownerName
// });

// export const setGreedyFilterStr = (greedyFilterStr) => ({
//   type: CONSTANTS.SET_GREEDY_FILTER_STR,
//   value: greedyFilterStr
// });

export const setSheetDataForm = (sheetDataForm) => ({
  type: CONSTANTS.SET_SHEET_DATA_FORM,
  value: sheetDataForm
});

// export const setFilterDataForm = (filterDataForm) => ({
//   type: CONSTANTS.SET_FILTER_DATA_FORM,
//   value: filterDataForm
// });

export const setFormInfos = (formInfos) => ({
  type: CONSTANTS.SET_FORM_INFOS,
  value: formInfos
});

export const setScreen = (screen) => ({
  type: CONSTANTS.SET_SCREEN,
  value: screen
});

export const setTaskLog = (taskLog) => ({
  type: CONSTANTS.SET_TASK_LOG,
  value: taskLog
});

export const setTaskLogPage = (taskLogPage) => ({
  type: CONSTANTS.SET_TASK_LOG_PAGE,
  value: taskLogPage
});