import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SearchInput from 'components/SearchInput/SearchInput';
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { FormControl, InputLabel, Select, Input, Chip, MenuItem } from '@material-ui/core';
// import { LocalizationProvider, DatePicker as MuiDatePicker } from '@mui/x-date-pickers';

const useStyles = makeStyles((theme) => ({
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const SearchFilter = (props) => {
  const classes = useStyles();
  // props.enable
  // props.style
  // props.title
  // props.placeholder
  // props.value
  // porps.onChange
  if (!props.enable) {
    return null;
  }
  return (
    <Tooltip title={props.title} style={props.style}>
      <SearchInput
        value={props.value}
        className={classes.searchInput}
        placeholder={ props.placeholder}
        onChange={props.onChange}
        endAdornment={(
          <IconButton size='small' onClick={(e) => {
            props.onChange({ target: { value: '' } });
          }}>
            <ClearIcon />
          </IconButton>
        )}
      />
    </Tooltip>
  );
};

const PickSelect = (props) => {
  // props.enable
  // props.style
  // props.title
  // props.value
  // props.options
  // props.onChange
  if (!props.enable) {
    return null;
  }
  return (
    <FormControl style={props.style}>
      <InputLabel id="update-range-mutiple-chip-label">{props.title}</InputLabel>
      <Select
        labelId="update-range-mutiple-chip-label"
        id="update-range-mutiple-chip"
        value={props.value}
        onChange={props.onChange}
        input={<Input id="update-range-multiple-chip" />}
        renderValue={selected => {
          const selectedOption = props.options.find((opt) => {
            return selected === opt.value;
          });
          return(
            <div style={{ display: 'flex', flexWrap: 'wrap' }} >
              <Chip style={{ width: '100%' }} label={selectedOption ? selectedOption.label : ''} />
            </div>
          );
        }}
        MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250, } } }}
      >
        {props.options.map((sObj) => (
          <MenuItem value={sObj.value}>{sObj.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const MutiPickSelect = (props) => {
  // props.enable
  // props.style
  // props.title
  // props.value
  // props.options => SEARCH_STATUS
  // props.onChange
  if (!props.enable) {
    return null;
  }
  return (
    <FormControl style={props.style}>
      <InputLabel id="status-mutiple-chip-label">{props.title}</InputLabel>
      <Select
        labelId="status-mutiple-chip-label"
        id="status-mutiple-chip"
        multiple
        value={props.value}
        onChange={props.onChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => {
          const selectedOptions = props.options.filter((opt) => {
            return selected.includes(opt.value);
          });
          return(
            <div style={{ display: 'flex', flexWrap: 'wrap' }} >
              {selectedOptions.map((opt) => ( <Chip label={opt.label}/> ))}
            </div>
          );
        }}
        MenuProps={{ PaperProps: { style: { maxHeight: 48 * 4.5 + 8, width: 250 } } }}
      >
        {props.options.map((sObj) => (
          <MenuItem value={sObj.value}>{sObj.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const DateRangeFilter = (props) => {
  // props.enable
  // props.title
  // props.leftValue
  // props.rightValue
  // props.onLeftChange
  // props.onRightValue
  if (!props.enable) {
    return null;
  }
  return (
    <FormControl style={props.style}>
      <label>{props.title}</label>
      <div>
        <div style={{ float: 'left' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="left-date-picker-dialog"
              value={props.leftValue || null}
              format="yyyy-MM-dd"
              onChange={(cValue) => {
                props.onLeftChange(cValue);
              }}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              InputProps={{
                startAdornment: (
                  <IconButton size='small' style={{order: 1}} onClick={(e) => {
                    props.onLeftChange(null);
                  }}>
                    <ClearIcon />
                  </IconButton>
                )
              }}
              InputAdornmentProps={{
                position: "end", style: {order: 2, marginLeft: 0}
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div style={{ float: 'left', margin: '5px 7px' }}>~</div>
        <div style={{ float: 'left' }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              id="right-date-picker-dialog"
              value={props.rightValue || null}
              format="yyyy-MM-dd"
              onChange={(cValue) => {
                props.onRightChange(cValue);
              }}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              InputProps={{
                startAdornment: (
                  <IconButton size='small' style={{order: 1}} onClick={(e) => {
                    props.onRightChange(null);
                  }}>
                    <ClearIcon />
                  </IconButton>
                )
              }}
              InputAdornmentProps={{
                position: "end", style: {order: 2, marginLeft: 0}
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </FormControl>
  );
};

const SearchInputWithClear = (props) => {
  if (!props.enable) {
    return null;
  }
  return (
    <Tooltip title={props.title} style={props.style}>
      <SearchInput
        value={props.value}
        placeholder={ props.placeholder}
        onChange={props.onChange}
        endAdornment={(
          <IconButton size='small' onClick={(e) => {
            props.onChange({ target: { value: '' } });
          }}>
            <ClearIcon />
          </IconButton>
        )}
      />
    </Tooltip>
  );
};

// const DatePicker = (props) => {
//   if (!props.enable) {
//     return null;
//   }
//   const value = props.value ? new Date(props.value) : null;
//   return (
//     <MuiPickersUtilsProvider dateAdapter={AdapterDateFns}>
//       <KeyboardDatePicker
//         sx={{ width: '100%' }}
//         maxDate={new Date('9999-12-31')}
//         label={props.label}
//         value={value}
//         format="yyyy-MM-dd"
//         onChange={props.onChange}
//         slotProps={{ actionBar: { actions: ['clear'] } }}
//         PopoverProps={{
//           anchorOrigin: { horizontal: "center", vertical: "bottom" },
//           transformOrigin: { horizontal: "center", vertical: "bottom" }
//         }}
//       />
//     </LocalizationProvider>
//   );
// };

export {
  SearchFilter,
  PickSelect,
  MutiPickSelect,
  DateRangeFilter,
  SearchInputWithClear
  // DatePicker
};
